// Package Imports
import React, { useEffect, useRef, useState } from 'react';

// Type Imports
import { MappAirSelectedFilter, Zephyr, ZephyrTypes } from '../../utils/interface';
import { Circle, Square, Triangle } from './Markers';
import styled from 'styled-components';
import { filter } from 'jszip';

// Types
interface LocationMarkerProps {
  isActive: boolean;
  isVisible: boolean;
  markerColour: string;
  selectUnit: Function;
  showLabel: boolean;
  unit: Zephyr;
  selectedFilters: MappAirSelectedFilter[];
  isALHDLoading: boolean;
}
interface MarkerProps {
  isActive: boolean;
  markerColour: string;
  showLabel: boolean;
  unit: Zephyr;
  isALHDLoading: boolean;
}

// issue with react fragment, needed to use a normal html element as wrapper(div)

const MarkerType = ({
  unit,
  markerColour,
  isActive,
  isALHDLoading
}: MarkerProps) => {
  if (unit.type === ZephyrTypes.virtual) {
    return <Square color={markerColour} isActive={isActive} />;
  }
  if (unit.type === ZephyrTypes.zephyr) {
    return <Circle color={markerColour} isActive={isActive} isALHDLoading={isALHDLoading} />;
  }
  if (unit.type === ZephyrTypes.aurns) {
    return <Triangle color={markerColour} isActive={isActive} />;
  }

  return (
    <div>
      <div
        className={`marker type-${unit.type} ${isActive ? 'active' : ''}`}
        style={{ background: markerColour }}
      >
        {unit.type === 1 ? (
          <div
            className="triangle-container"
            style={{ borderBottomColor: markerColour }}
          />
        ) : null}
      </div>
    </div>
  );
};

const Tooltip = styled.div`
  display: none;
  position: absolute;
  bottom: -46px;
  border-radius: 8px;
  background-color: white;
  padding: 6px;
  font-size: 14px;
  border: 1px solid lightgray;
  width: max-content;
`;

const LocationMarkerWrapper = styled.div`
  position: relative;
  
  &:hover ${Tooltip} {
    display: block;
  }
`;

const LocationMarker = ({
  isActive,
  isVisible,
  markerColour,
  selectUnit,
  showLabel,
  unit,
  selectedFilters,
  isALHDLoading
}: LocationMarkerProps) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (wrapperRef.current) {
      const parentElement = wrapperRef.current.parentElement;
      if (parentElement) {
        parentElement.style.zIndex = isActive ? '1' : '0';
      }
    }
  }, [isActive]);

  const handleMouseEnter = () => {
    if (wrapperRef.current) {
      const parentElement = wrapperRef.current.parentElement;
      if (parentElement && !isActive) {
        parentElement.style.zIndex = '2';
      }
    }
  };

  const handleMouseLeave = () => {
    if (wrapperRef.current) {
      const parentElement = wrapperRef.current.parentElement;
      if (parentElement && !isActive) {
        parentElement.style.zIndex = '0';
      }
    }
  };

  const showLabels = (type: number) => {
    const unitFilter = selectedFilters.find((filters: MappAirSelectedFilter) => {
      return type === 0 ? filters.name === 'zephyr' : type === 100 ? filters.name === 'virtual' : filters.name === 'aurn';
    });
    if (unitFilter) {
      const { showAllLabels, showSelectedLabel } = unitFilter;
      if (!showAllLabels && !showSelectedLabel) return;
      if (showAllLabels) return 'block';
      if (showSelectedLabel) return isActive ? 'block' : undefined;
    }
  }
  return (
    <div>
      <LocationMarkerWrapper
        ref={wrapperRef}
        className={`marker-container ${isVisible ? 'marker-visible' : 'marker-invisible'}`}
        id={`${unit.id_pod}-${unit.type}`}
        onClick={() => selectUnit(unit)}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <MarkerType
          unit={unit}
          markerColour={markerColour}
          showLabel={showLabel}
          isActive={isActive}
          isALHDLoading={isALHDLoading}
        />

        <Tooltip style={{ display: showLabels(unit.type) }}>
          <p className="m-0">{unit.name ?? '-'}</p>
        </Tooltip>
      </LocationMarkerWrapper>
    </div>
  );
};

export default LocationMarker;
