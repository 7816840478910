import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { connect, useSelector } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';

import {
  increaseArrivedRequests,
  proceedWithAnalysis,
  restartArrivedRequests,
  setIsViewLoaded,
  setNullableUnits,
  setUnitHistoriesList,
  setUnitHistoriesLoading,
  setZephyrCoveragesList,
} from '../../../actions/dataAnalytics';

import {
  customTimePeriod,
  virtualZephyrsPollutants,
} from '../../../utils/consts';

import { decompress } from 'lz-string';
import moment from 'moment';
import {
  getZephyrData,
  setDraggedUnit,
  setZephyrInEditMode,
  setZephyrs,
} from '../../../actions/zephyrs';
import {
  dateFinder,
  defaultStartEndFinder,
  startEndFinder,
  utcConvertor,
} from '../../../utils/functions/dateFinder';
import { speciesExtentFinder } from '../../../utils/functions/speciesExtentFinder';
import {
  AveragingPeriod,
  AveragingPeriods,
  DataConfig,
  DisplayConfig,
  PeriodOptions,
  Pollutant,
  ReduxState,
  TimePeriod,
  TimePeriods,
  UrlQueryStringParams,
  UserInfo,
  Zephyr,
  ZephyrCoverages,
} from '../../../utils/interface';
import Accordion from '../../shared_components/Accordions';
import { InitStateFilters } from '../../shared_components/Aside';
import BasicPopover from '../../shared_components/BasicPopover';
import { getZephyrType } from '../ReactGridLayout/ReactGridLayout';

import SaveViewIcon from '../../../assets/images/save-view.svg';
import LoadViewIcon from '../../../assets/images/load-view.svg';
import RunViewIcon from '../../../assets/images/run-view.svg';
import ArrowRightIcon from '../../../assets/images/arrow-right.svg';
import HTMLReactParser from 'html-react-parser';
import { useLocation } from 'react-router-dom';
import Spinner from '../../shared_components/Spinner';
import UnitList from './UnitList/UnitList';
import AnalysisTour from './AnalysisTour';

function getDateByPeriod(value: PeriodOptions): Date | null {
  const now = new Date();

  const periodMap: Record<PeriodOptions, () => Date | null> = {
    '24': () => new Date(now.getTime() - 24 * 60 * 60 * 1000),
    '48': () => new Date(now.getTime() - 48 * 60 * 60 * 1000),
    '72': () => new Date(now.getTime() - 72 * 60 * 60 * 1000),
    'week': () => new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000),
    'month': () => {
      const newDate = new Date(now);
      newDate.setMonth(now.getMonth() - 1);
      return newDate;
    },
    'custom': () => null,
  };

  const calculateFn = periodMap[value];
  return calculateFn() ? calculateFn() : null;
}

interface ZephyrListGrouped {
  [key: string]: Zephyr[];
}
interface FilterBar1Props {
  displayConfig: DisplayConfig;
  timePeriods: TimePeriods;
  averagingPeriods: AveragingPeriods;
  queryStringParams: UrlQueryStringParams;
  dataConfig: DataConfig;
  getZephyrData: Function;
  setUnitHistoriesList: Function;
  setUnitHistoriesLoading: Function;
  unitHistoriesLoading: boolean;
  unitHistoriesList: any;
  dseOn: boolean;
  bearerToken: string | null;
  increaseArrivedRequests: Function;
  restartArrivedRequests: Function;
  nullableUnits: number[];
  setNullableUnits: Function;
  toProceed: boolean | null;
  proceedWithAnalysis: Function;
  viewLoaded: boolean;
  setIsViewLoaded: Function;
  isCartridgeDataLoading: boolean | null;
  isGetZephyrHistoricalDataLoading: boolean;
  isDataAvailabilityChartLoading: boolean;
  mappairPointMode: boolean;
  unitList: Zephyr[];
  setZephyrCoveragesList: Function;
  toggleSaveAndLoadViewModals: Function;
  zephyrCoveragesList: any[];
  zephyrsPollutantData: any;
  setZephyrsPollutantData: Function;
  selectedTimePeriod: TimePeriod | null;
  setSelectedTimePeriod: Function;
  selectedTimeAveraging: AveragingPeriod | null;
  setSelectedTimeAveraging: Function;
  isViewLoaded: boolean;
  setViewLoaded: Function;
  customDate: { start: Date, end: Date };
  setCustomDate: Function;
  startDate: Date;
  setStartDate: Function;
  endDate: Date;
  setEndDate: Function;
  initialRenderSet: boolean;
  setInitialRenderSet: Function;
  userInfo: UserInfo;
};

export interface AnalyticsOptionsProperties {
  id: number;
  text: string;
  isSelected: boolean;
}

export interface AnalyticsOptions {
  devices: AnalyticsOptionsProperties;
  pollutants: AnalyticsOptionsProperties;
  timeAverage: AnalyticsOptionsProperties;
  timePeriod: AnalyticsOptionsProperties;
}

const tPaP = {
  timePeriods: 'tP',
  averagingPeriods: 'aP',
};

const ZephyrsKey = '0'
const VirtualZephyrsKey = "100";
export const selectedDeviceKeys = [ZephyrsKey, VirtualZephyrsKey];


const chemicalJSXMap: { [key: string]: React.ReactNode } = {
  "NO2": (
    <span>
      NO
      <sub>2</sub>
    </span>
  ),
  "O3": (
    <span>
      O
      <sub>3</sub>
    </span>
  ),
  "PM1": (
    <span>
      PM
      <sub>1</sub>
    </span>
  ),
  "PM10": (
    <span>
      PM
      <sub>10</sub>
    </span>
  ),
  "PM25": (
    <span>
      PM
      <sub>2.5</sub>
    </span>
  ),
  "CO2": (
    <span>
      CO
      <sub>2</sub>
    </span>
  ),
  "SO2": (
    <span>
      SO
      <sub>2</sub>
    </span>
  ),
  "H2S": (
    <span>
      H
      <sub>2</sub>
      S
    </span>
  ),
};

export interface AnchorObject {
  anchorId: number,
  anchorEl: HTMLButtonElement | null
};

// View Component
const FilterBar1 = ({
  unitHistoriesLoading,
  isGetZephyrHistoricalDataLoading,
  mappairPointMode,
  toggleSaveAndLoadViewModals,
  setZephyrCoveragesList,
  isDataAvailabilityChartLoading,
  displayConfig,
  timePeriods,
  averagingPeriods,
  queryStringParams,
  dataConfig,
  getZephyrData,
  setUnitHistoriesList,
  setUnitHistoriesLoading,
  unitHistoriesList,
  dseOn,
  bearerToken,
  increaseArrivedRequests,
  restartArrivedRequests,
  nullableUnits,
  setNullableUnits,
  toProceed,
  proceedWithAnalysis,
  viewLoaded,
  setIsViewLoaded,
  isCartridgeDataLoading,
  unitList,
  zephyrCoveragesList,
  zephyrsPollutantData,
  setZephyrsPollutantData,
  selectedTimePeriod,
  setSelectedTimePeriod,
  selectedTimeAveraging,
  setSelectedTimeAveraging,
  isViewLoaded,
  setViewLoaded,
  customDate,
  setCustomDate,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  initialRenderSet,
  setInitialRenderSet,
  userInfo
}: FilterBar1Props) => {
  const analysisTourOpen = useSelector((state: ReduxState) => state.analysisTourOpen);
  const [editingModeFilterBarOn, toggleEditingMode] = useState(true);



  const [deviceApplied, setDeviceApplied] = useState(false);
  const [pollutantApplied, setPollutantApplied] = useState(false);
  const [timePeriodApplied, setTimePeriodApplied] = useState(false);
  const [timeAverageApplied, setTimeAverageApplied] = useState(false);







  const [unitHistories, setUnitHistories] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);

  // extract of analytics buttons below - 2 states
  const [toggleCustom, setToggleCustom] = useState<boolean>(false);

  const [accordionIndexes, setAccordionIndexes] = useState<{ [key: number]: boolean }>({
    0: true,
    1: false,
    2: false,
    3: false
  });

  const [anchorElements, setAnchorElements] = useState<AnchorObject[]>([
    { anchorId: 0, anchorEl: null },
    { anchorId: 1, anchorEl: null },
    { anchorId: 2, anchorEl: null },
    { anchorId: 3, anchorEl: null },
  ]);

  const location = useLocation();

  useEffect(() => {
    if (initialRenderSet) {
      (async () => {
        await generateHistoricalData();
        setInitialRenderSet(false);
      })();
    }
  }, [initialRenderSet]);

  useEffect(() => {
    if (unitList?.length) {
      setZephyrsPollutantData((prevState: any) => {
        const prevStateCopy = { ...prevState };
        prevStateCopy.filteredZephyrs = [...getUpdatedFilteredZephyrsList()];
        return prevStateCopy;
      });
    }
  }, [unitList]);

  useEffect(() => {
    if (!loading && unitHistories.length > 0) {
      setUnitHistoriesList({
        id: Math.random(),
        isLoaded: true,
        historiesList: [...unitHistories],
      });
      setUnitHistoriesLoading(false);
    }
    proceedWithAnalysis(null);
  }, [loading]);

  useEffect(() => {
    if (nullableUnits.length > 0) setNullableUnits([]);
  }, [zephyrsPollutantData.filteredZephyrs, dseOn]);

  const getUpdatedFilteredZephyrsList = () => {
    let zephyrsCopy: Zephyr[] = [...unitList];
    const { filteredZephyrs } = zephyrsPollutantData;
    zephyrsCopy = zephyrsCopy.map((zephyr: Zephyr) => {
      if (filteredZephyrs.length) {
        const filteredZephyr = filteredZephyrs.find(
          (z: Zephyr) => z.zNumber === zephyr.zNumber,
        );
        if (filteredZephyr?.isSelected) zephyr.isSelected = true;
        else zephyr.isSelected = false;
      } else zephyr.isSelected = false;
      return zephyr;
    });
    return zephyrsCopy;
  };

  const convertedTimeToUTCAndUpdatedDataConfig = () => {
    const defaultStartEnd = defaultStartEndFinder();
    let { end, start } = defaultStartEnd;
    if (selectedTimePeriod && selectedTimePeriod.timeOption === 'custom') {
      start = moment(customDate.start)
        .set({ h: 0, m: 0, s: 0 })
        .format('YYYYMMDDHHmm');
      const unformattedEnd = moment(customDate.end).set({
        h: 23,
        m: 59,
        s: 59,
      });
      const current = moment();
      const selectedEnd = current < unformattedEnd ? current : unformattedEnd;
      end = selectedEnd.format('YYYYMMDDHHmm');
    } else if (selectedTimePeriod && selectedTimePeriod.timeOption) {
      const startEnd = startEndFinder(selectedTimePeriod.timeOption);
      start = startEnd.start;
      end = startEnd.end;
    }

    // Convert start/end
    const startUTC = utcConvertor(start);
    const endUTC = utcConvertor(end);
    const updatedDataConfig = queryStringParams.ctv
      ? { ...dataConfig, ctv: queryStringParams.ctv }
      : dataConfig;
    return { startUTC, endUTC, updatedDataConfig, start, end };
  };

  const getZephyrHistoryData = async (zephyr: Zephyr) => {
    const {
      startUTC,
      endUTC,
      updatedDataConfig,
      start,
      end,
    } = convertedTimeToUTCAndUpdatedDataConfig();
    const { pollutantData } = zephyrsPollutantData;
    const selectedPollutants = pollutantData.filter(
      (pollutant: Pollutant) => pollutant.isChecked,
    );
    const { averagingOption } = selectedTimeAveraging!;
    const newUnitHistory = await getZephyrData(
      zephyr,
      selectedTimePeriod!.timeOption,
      startUTC,
      endUTC,
      updatedDataConfig,
      true,
      bearerToken,
      1,
      location.pathname === '/',
      dseOn ? dseOn : undefined,
      selectedPollutants,
      location.pathname.includes('analysis'),
      averagingOption
    );
    return { newUnitHistory, start, end };
  };

  const resetViewsStateFromLocalStorage = () => {
    // this function needs to be reusable as we use it in Header.tsx as well
    if (
      JSON.parse(localStorage.getItem('unitHistoryViews') as any) &&
      JSON.parse(localStorage.getItem('unitHistoryViews') as any).length > 0
    ) {
      const views = JSON.parse(localStorage.getItem('unitHistoryViews') as any);
      views.forEach((view: any) => {
        if (view.isLoaded && view.isSelected) {
          view.isLoaded = false;
          view.isSelected = false;
        }
      });
      localStorage.setItem('unitHistoryViews', JSON.stringify([...views]));
    }
  };

  const resetConfigurations = () => {
    setZephyrsPollutantData((prevState: any) => {
      const prevStateCopy = { ...prevState };
      prevStateCopy.filteredZephyrs = prevStateCopy.filteredZephyrs.map(
        (zephyr: Zephyr) => {
          if (zephyr.isSelected) zephyr.isSelected = false;
          return zephyr;
        },
      );
      prevStateCopy.pollutantData = null;
      return prevStateCopy;
    });
    setSelectedTimeAveraging(null);
    setSelectedTimePeriod(null);
    setUnitHistories([]);
  };

  const removeUnitHistoriesDuplicates = (
    selectedZephyrs: Zephyr[],
    prevUnitHistories: any,
  ) => {
    const selectedZephyrsKeys = selectedZephyrs.map((z: Zephyr) => z.zNumber);
    const filteredUnitHistories: any = [];
    selectedZephyrsKeys.forEach((zNumber: number) => {
      const validZephyr = prevUnitHistories.filter(
        (z: any) => z && parseInt(Object.keys(z)[0]) === zNumber,
      );
      filteredUnitHistories.push(validZephyr[0]);
    });
    return filteredUnitHistories;
  };

  const updateSelectedAnalyticsOptions = (
    newUnitHistory: any,
    zephyrZNumber: number,
    name: string,
    start: any,
    end: any,
    HasMetStation: number | null
  ) => {
    const { pollutantData } = zephyrsPollutantData;
    let newUnitHistoryObj: any = {};
    newUnitHistory['name'] = name;
    newUnitHistory['selectedPollutants'] = pollutantData.filter(
      (p: Pollutant) => p.isChecked,
    );
    newUnitHistory['selectedAveragingPeriod'] = selectedTimeAveraging;
    newUnitHistory['selectedTimePeriod'] = selectedTimePeriod;
    newUnitHistory['selectedDate'] = { start, end };
    newUnitHistory['HasMetStation'] = HasMetStation;
    if (selectedTimePeriod?.timeOption === 'custom')
      newUnitHistory['selectedCustomDate'] = customDate;
    else if (
      (selectedTimePeriod as any)?.timeOption !== 'custom' &&
      'selectedCustomDate' in newUnitHistory
    )
      newUnitHistory['selectedCustomDate'] = null;
    newUnitHistoryObj[zephyrZNumber] = newUnitHistory;
    return newUnitHistoryObj;
  };

  const handleUnitExistanceValidationAndGetUpdatedUnitHistories = (
    prevUnitHistories: any,
    selectedZephyrs: Zephyr[],
    newUnitHistoryObj: any,
    zephyrZNumber: number,
  ) => {
    const isZephyrInList = prevUnitHistories.find(
      (z: any) => z && parseInt(Object.keys(z)[0]) === zephyrZNumber,
    );
    if (!isZephyrInList)
      prevUnitHistories = [...prevUnitHistories, newUnitHistoryObj];
    else {
      const updatedUnitHistories = [...prevUnitHistories];
      updatedUnitHistories[
        updatedUnitHistories.indexOf(isZephyrInList)
      ] = newUnitHistoryObj;
      prevUnitHistories = [...updatedUnitHistories];
    }
    if (
      prevUnitHistories.length > 0 &&
      prevUnitHistories.length > selectedZephyrs.length
    )
      prevUnitHistories = [
        ...removeUnitHistoriesDuplicates(selectedZephyrs, prevUnitHistories),
      ];
    return prevUnitHistories;
  };

  const hasSelectedZephyrAndPollutant = () => {
    const { filteredZephyrs, pollutantData } = zephyrsPollutantData;
    const hasSelectedZephyr = filteredZephyrs?.some(
      (z: Zephyr) => z.isSelected,
    );
    const hasCheckedPollutant = pollutantData?.some(
      (p: Pollutant) => p.isChecked,
    );
    return { hasSelectedZephyr, hasCheckedPollutant };
  };

  const handleFilterBarModeUpdate = () => {
    if (!userInfo.isPublic || window.innerWidth >= 767) toggleEditingMode(!editingModeFilterBarOn);
    else toggleEditingMode(true);
  };

  const checkBeforeGenerateHistoricalData = async () => {
    const { filteredZephyrs, pollutantData } = zephyrsPollutantData;
    const hasSelectedZephyr = filteredZephyrs?.some(
      (z: Zephyr) => z.isSelected,
    );
    const hasCheckedPollutant = pollutantData?.some(
      (p: Pollutant) => p.isChecked,
    );

    if (
      hasSelectedZephyr &&
      hasCheckedPollutant &&
      selectedTimePeriod &&
      selectedTimeAveraging
    ) {
      await generateHistoricalData();
      handleFilterBarModeUpdate();
    } else {
      alert(
        'Please select at least one unit, pollutant, time average, and time period!',
      );
    }
  };

  const generateHistoricalData = async () => {
    // call getZephyrData function to get the last selected zephyr's historical data and update the unitHistories state
    const { filteredZephyrs } = zephyrsPollutantData;
    const selectedZephyrs = filteredZephyrs.filter((z: Zephyr) => z.isSelected);

    if (selectedZephyrs.length > 0) {
      (async () => {
        setUnitHistoriesLoading(true);
        setLoading(true);
        let counter = 0;
        for await (const zephyr of selectedZephyrs) {
          getZephyrHistoryData(zephyr).then((zephyrHistoryData) => {
            const { newUnitHistory, start, end } = zephyrHistoryData;
            if (!newUnitHistory.data && !nullableUnits.includes(zephyr.zNumber))
              setNullableUnits(zephyr.zNumber);
            setUnitHistories((prevUnitHistories: any) => {
              const { name, zNumber: zephyrZNumber, HasMetStation } = zephyr;
              const newUnitHistoryObj = updateSelectedAnalyticsOptions(
                newUnitHistory,
                zephyrZNumber,
                name,
                start,
                end,
                HasMetStation
              );
              prevUnitHistories = [
                ...handleUnitExistanceValidationAndGetUpdatedUnitHistories(
                  prevUnitHistories,
                  selectedZephyrs,
                  newUnitHistoryObj,
                  zephyrZNumber,
                ),
              ];
              return prevUnitHistories;
            });
            counter++;
            increaseArrivedRequests();
            if (counter === selectedZephyrs.length) {
              setLoading(false);
              restartArrivedRequests();
            }
          });
        }
      })();
      return;
    }
    alert('Cannot stack more than 5 units!');
  };

  useEffect(() => {
    const handleFilterBarUpdateOnPublicPortals = () => {
      if (window.innerWidth < 768) toggleEditingMode(true);
    };
    if (userInfo.isPublic) {
      window.addEventListener('resize', handleFilterBarUpdateOnPublicPortals);

      return () => {
        window.removeEventListener('resize', handleFilterBarUpdateOnPublicPortals)
      };
    }
  }, []);

  useEffect(() => {
    if (isViewLoaded) {
      (async () => {
        await checkBeforeGenerateHistoricalData();
        setViewLoaded(false);
      })();
    }
  }, [isViewLoaded])

  const onChangeDateRangePicker = (dates: any[]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    if (start && end) {
      setCustomDate({ start: start.toISOString(), end: end.toISOString() });
    }
  };

  const handlePollutantSelection = (pollutant: Pollutant) => {
    setPollutantApplied(true)
    const { pollutantData } = zephyrsPollutantData;
    const pollutantDataCopy = [...pollutantData];
    const pollutantToChange = pollutantDataCopy.find(
      (p: Pollutant) => p.dataIdentifier === pollutant.dataIdentifier,
    );
    if (pollutantToChange) {
      const pollutantIdx = pollutantDataCopy.indexOf(pollutantToChange);
      pollutantToChange.isChecked = !pollutantToChange.isChecked;
      pollutantDataCopy[pollutantIdx] = pollutantToChange;
    }
    setZephyrsPollutantData((prevState: any) => {
      const prevStateCopy = { ...prevState };
      prevStateCopy.pollutantData = pollutantDataCopy;
      return prevStateCopy;
    });
  };

  const bulkPollutantsStateUpdate = (bool: boolean) => {
    setZephyrsPollutantData((prevState: any) => {
      const prevStateCopy = { ...prevState };
      prevStateCopy.pollutantData.forEach((pollutant: Pollutant) => {
        if (
          bool &&
          !pollutant.isChecked &&
          pollutant.dataIdentifier !== 'tempC' &&
          pollutant.dataIdentifier !== 'ambTempC' &&
          pollutant.dataIdentifier !== 'ambHumidity' &&
          pollutant.dataIdentifier !== 'ambPressure'
        )
          pollutant.isChecked = bool;
        else if (
          !bool &&
          pollutant.isChecked &&
          pollutant.dataIdentifier !== 'tempC' &&
          pollutant.dataIdentifier !== 'ambTempC' &&
          pollutant.dataIdentifier !== 'ambHumidity' &&
          pollutant.dataIdentifier !== 'ambPressure'
        )
          pollutant.isChecked = bool;
      });
      return prevStateCopy;
    });
  };

  const selectAllPollutants = (event: any) => {
    setPollutantApplied(true)
    if (event.target.checked) {
      bulkPollutantsStateUpdate(true);
      return;
    }
    bulkPollutantsStateUpdate(false);
  };

  const areAllPollutantCheckboxesChecked = () => {
    const { pollutantData } = zephyrsPollutantData;
    const pollutants = pollutantData
      .filter(
        (pollutant: Pollutant) =>
          pollutant.dataIdentifier !== 'tempC' &&
          pollutant.dataIdentifier !== 'ambTempC' &&
          pollutant.dataIdentifier !== 'ambHumidity' &&
          pollutant.dataIdentifier !== 'ambPressure',
      )
      .map((pollutant: Pollutant) => pollutant);
    return (
      pollutants.filter((pollutant: Pollutant) => pollutant.isChecked)
        .length === pollutants.length
    );
  };

  const extractPolluntantUnitCards = () => {
    const { pollutantData } = zephyrsPollutantData;
    const filteredPollutantData = [...pollutantData].filter(
      (pollutant: Pollutant) =>
        pollutant.dataIdentifier !== 'tempC' &&
        pollutant.dataIdentifier !== 'ambHumidity' &&
        pollutant.dataIdentifier !== 'ambPressure',
    );
    filteredPollutantData
      .unshift({ dataIdentifier: 'All' });

    return filteredPollutantData.map(
      (pollutant: Pollutant) => {
        const { dataIdentifier } = pollutant;
        if (dataIdentifier === 'All') {
          return (
            <label
              htmlFor={pollutant.dataIdentifier}
              key={pollutant.dataIdentifier}
              className="list"
            >
              <input
                type="checkbox"
                id={pollutant.dataIdentifier}
                name={pollutant.dataIdentifier}
                value={pollutant.dataIdentifier}
                checked={areAllPollutantCheckboxesChecked()}
                onChange={selectAllPollutants}
              />
              {pollutant.dataIdentifier}
            </label>
          );
        }

        let inputLabel = chemicalJSXMap[dataIdentifier] || dataIdentifier
        if (dataIdentifier.includes('particulate')) {
          const particulate = dataIdentifier.split('particulate')[1]
          inputLabel = chemicalJSXMap[particulate] || particulate
        }

        return (
          <label
            htmlFor={pollutant.dataIdentifier}
            key={pollutant.dataIdentifier}
            className="list"
          >
            <input
              type="checkbox"
              id={pollutant.dataIdentifier}
              name={pollutant.dataIdentifier}
              value={pollutant.dataIdentifier}
              checked={(pollutant as Pollutant).isChecked}
              onChange={() => {
                handlePollutantSelection(pollutant as Pollutant);
              }}
            />
            {inputLabel}
          </label>
        );
      },
    );
  };

  const getGuardRailsStylesForVZephyrs = (filteredZephyrs: Zephyr[] | undefined, option: string, averagingOption: string): React.CSSProperties | undefined => {
    return filteredZephyrs && filteredZephyrs.find((zephyr: Zephyr) => zephyr.type === 100 && zephyr.isSelected) && option === 'aP' && averagingOption !== 'Hourly average on the hour' && averagingOption !== '8 hour average at midnight and 8am and 4pm' && averagingOption !== 'Daily average at midnight' ?
      { pointerEvents: 'none', opacity: 0.4 } : undefined;
  };

  const extractTimeAndAveragingPeriodsUnitCards = (option: string, filteredZephyrs?: Zephyr[]) => {
    const modifiedTimePeriods = Object.assign({}, timePeriods, {
      periods: [...timePeriods.periods, { ...customTimePeriod }],
    });
    const modifiedAveragingPeriods = {
      periods: averagingPeriods.periods.filter(
        (aPeriod: AveragingPeriod) => {
          if (userInfo.isPublic) return aPeriod.averagingOption === 'Hourly average on the hour' || aPeriod.averagingOption === '8 hour average at midnight and 8am and 4pm' || aPeriod.averagingOption === 'Daily average at midnight'
          return aPeriod.averagingOption !== 'Unaveraged'
        },
      ),
    };
    const curOption: any =
      option === 'tP' ? modifiedTimePeriods : modifiedAveragingPeriods;
    const curState =
      option === 'tP' ? selectedTimePeriod : selectedTimeAveraging;
    return (
      <div className="time-range-pickers">
        <div>
          {curOption.periods.map((period: TimePeriod | AveragingPeriod) => (
            <div
              key={
                option === 'tP'
                  ? (period as TimePeriod).timeOption
                  : (period as AveragingPeriod).averagingOption
              }
            >
              {option === 'aP' ||
                ('timeOption' in period && period.timeOption !== 'custom') ? (
                <>
                  <label
                    className="list"
                    htmlFor={`radio-${option === 'tP'
                      ? (period as TimePeriod).timeOption
                      : (period as AveragingPeriod).averagingOption
                      }`}
                    style={getGuardRailsStylesForVZephyrs(filteredZephyrs, option, (period as AveragingPeriod).averagingOption)}
                  >
                    <input
                      type="radio"
                      id={`radio-${option === 'tP'
                        ? (period as TimePeriod).timeOption
                        : (period as AveragingPeriod).averagingOption
                        }`}
                      name={`radio ${option === 'tP' ? 'tP' : 'aP'}`}
                      checked={
                        curState
                          ? curState.labelHTML === period.labelHTML
                          : false
                      }
                      value={period.labelHTML}
                      onChange={() => {
                        if (option === 'tP') {
                          setTimePeriodApplied(true)
                          const p = period as TimePeriod;
                          const { timeOption } = p;
                          setSelectedTimePeriod(p);

                          const dateAgo = getDateByPeriod(timeOption);
                          if (dateAgo) {
                            setStartDate(dateAgo);
                            setEndDate(new Date());
                          }

                          if (toggleCustom) {
                            setToggleCustom(false);
                          }
                        } else {
                          setTimeAverageApplied(true)
                          setSelectedTimeAveraging(period as AveragingPeriod);
                        }
                      }}
                    />
                    {option === 'aP' && period.labelHTML === 'None'
                      ? 'Raw'
                      : period.labelHTML}
                  </label>
                </>
              ) : (
                <div>
                  <label className="list radio-custom" htmlFor="radio-custom">
                    <input
                      id="radio-custom"
                      type="radio"
                      name={`radio ${option}`}
                      defaultChecked={
                        curState
                          ? curState.labelHTML === period?.labelHTML
                          : false
                      }
                      value={period?.labelHTML}
                      onChange={() => {
                        setToggleCustom(!toggleCustom);
                        setSelectedTimePeriod!(period as TimePeriod);
                      }}
                    />
                    Custom
                  </label>
                </div>
              )}
            </div>
          ))}
        </div>
        <div className="border-left gray">
          {toggleCustom && option === 'tP' ? (
            <DatePicker
              selected={startDate}
              dateFormat="dd/MM/yyyy"
              onChange={onChangeDateRangePicker}
              startDate={startDate}
              endDate={endDate}
              selectsRange
              inline
            />
          ) : null}
        </div>
      </div>
    );
  };

  const addUnitToAnalyticsList = (unit: Zephyr) => {
    setDeviceApplied(true);
    setZephyrsPollutantData((prevState: any) => {
      const prevStateCopy = { ...prevState };
      const unitToChange = prevStateCopy.filteredZephyrs.find(
        (z: Zephyr) => z.zNumber === unit.zNumber,
      );
      if (unitToChange) {
        unitToChange.isSelected = !unitToChange.isSelected;
      }
      return prevStateCopy;
    });
  };

  const extractAnalyticsOptions = () => {
    const { filteredZephyrs, pollutantData } = zephyrsPollutantData;
    const areAnyZephyrsSelected = filteredZephyrs.some(
      (zephyr: Zephyr) => zephyr.isSelected,
    );
    const isPollutantDataEmpty =
      pollutantData !== null && pollutantData.length === 0;
    const getDateRange = (dateObj: Date | string): string => {
      const formatter = new Intl.DateTimeFormat('en-GB', {
        day: '2-digit',
        month: 'short',
        year: 'numeric',
      });

      if (typeof dateObj === 'string') {
        // Handle the case when dateObj is a string (e.g., '2024-02-06T19:10:12.000Z')
        const parsedDate = new Date(dateObj);
        const formattedDate = formatter.format(parsedDate);
        return formattedDate;
      }

      // For Date objects, format as usual
      const formattedDate = dateObj ? formatter.format(dateObj) : '';
      return formattedDate;
    };

    const getUnitHistoriesListNames = (
      filteredZephyrs: Zephyr[] = [],
    ): string => {
      const names = filteredZephyrs
        .map((zephyr: Zephyr) => {
          if (zephyr.isSelected) {
            return (
              zephyr.zNumber ||
              zephyr.name ||
              zephyr.alias ||
              ''
            ).toString();
          }
          return null;
        })
        .filter((name) => name); // This will filter out falsy values (null, undefined, '')

      return names.join(', ');
    };

    const getCheckedPollutantsList = (
      pollutants: Pollutant[] = [],
    ): React.ReactNode[] => {
      if (!pollutants || !pollutants.length) return [''];

      const checkedPollutants = pollutants.filter(
        (pollutant: Pollutant) => pollutant.isChecked,
      );
      const allChecked = areAllPollutantCheckboxesChecked();

      if (allChecked) {
        return ['All pollutants'];
      } else {
        return checkedPollutants.map((pollutant: Pollutant) =>
          HTMLReactParser(pollutant.HTMLLabel),
        );
      }
    };

    const getSelectedTimePeriodText = (customDate: any, startDate?: Date, endDate?: Date) => {
      let text = selectedTimePeriod?.labelHTML || '';
      if (text === 'Custom') {
        text =
          getDateRange(customDate.start) + ' - ' + getDateRange(customDate.end);
      }
      if (startDate && endDate) {
        text = getDateRange(startDate) + ' - ' + getDateRange(endDate);
      }
      return text;
    };

    const getSelectedTimeAverageText = () => {
      const text = selectedTimeAveraging?.labelHTML || '';
      return text;
    };

    const toggleFilter1Edit = (event: any) => {
      toggleEditingMode(!editingModeFilterBarOn);
    };

    const pollutantsListElements: React.ReactNode[] = getCheckedPollutantsList(
      zephyrsPollutantData.pollutantData,
    );

    const selectedDevicesText = getUnitHistoriesListNames(filteredZephyrs);
    const selectedDevicesTextElement = selectedDevicesText && deviceApplied ? (
      <div className="text-ellipsis" title={selectedDevicesText} style={{ maxWidth: 208 }}>
        {selectedDevicesText}
      </div>
    ) : <span>Devices</span>

    const selectedPollutantsTextElement = pollutantsListElements.length > 0 && pollutantApplied ? (
      <div className="text-ellipsis px-16">
        {pollutantsListElements.map((element, index) => (
          <span key={index} style={{ display: 'inline-block', verticalAlign: 'middle' }}>
            {element}
            {index < pollutantsListElements.length - 1 && (
              <>{', '}<span style={{ marginRight: '0.25em' }}></span></>
            )}
          </span>
        ))}
      </div>
    ) : <span>Pollutants</span>

    const selectedTimePeriodTextElement = selectedTimePeriod?.labelHTML && timePeriodApplied ? (
      <span>{selectedTimePeriod.labelHTML}</span>
    ) : <span>Time Period</span>

    const selectedTimeAveragingElement = selectedTimeAveraging?.labelHTML && timeAverageApplied ? (
      <span>{selectedTimeAveraging.labelHTML}</span>
    ) : <span>Time Average</span>

    return (
      <div
        className={`data-analytics-task-bar-wrapper ${
          unitHistoriesList.isLoaded ? 'loaded-units' : ''
        }`}
      >
        {unitHistoriesList.isLoaded && !analysisTourOpen ? (
          <div
            className={`non-editable justify-content-between cta ${
              editingModeFilterBarOn ? 'd-none' : 'slide-in-top'
            }`}
            onClick={toggleFilter1Edit}
          >
            <div
              className={`input-container${
                userInfo.isPublic ? ' input-container-public' : ''
              }`}
            >
              <div className="input-container-btn devices">
                {selectedDevicesTextElement}
              </div>

              <div className="input-container-btn pollutants">
                {selectedPollutantsTextElement}
              </div>

              <div className="input-container-btn time-period">
                {getSelectedTimePeriodText(customDate, startDate, endDate)}{' '}
                (UTC)
              </div>
              <div className="input-container-btn time-average">
                {getSelectedTimeAverageText()}
              </div>
              <div
                className="input-container-cta rounded-circle"
                onClick={() => {
                  // checkBeforeGenerateHistoricalData;
                  toggleEditingMode(!editingModeFilterBarOn);
                }}
              >
                <img src={ArrowRightIcon} className="rounded-circle" />
              </div>
            </div>
            {!userInfo.isPublic && (
              <div className="save-load-wrapper">
                {unitHistories && (
                  <button
                    className="prompt-button save-view-button h-40 button-icon-left"
                    type="button"
                    disabled={unitHistoriesLoading ? true : false}
                    style={{
                      marginRight: 15,
                      cursor: unitHistoriesLoading ? 'auto' : 'pointer',
                    }}
                    onClick={() => {
                      toggleSaveAndLoadViewModals('save');
                    }}
                  >
                    <img src={SaveViewIcon} />
                    Save View
                  </button>
                )}
                <button
                  className="prompt-button load-view-button h-40 button-icon-left"
                  type="button"
                  disabled={unitHistoriesLoading ? true : false}
                  style={{
                    cursor: unitHistoriesLoading ? 'auto' : 'pointer',
                  }}
                  onClick={() => {
                    toggleSaveAndLoadViewModals('load');
                  }}
                >
                  <img src={LoadViewIcon} />
                  Load View
                </button>
              </div>
            )}
          </div>
        ) : null}

        <div
          className={`position-relative data-analytics-task-bar ${
            editingModeFilterBarOn || analysisTourOpen
              ? 'slide-in-top'
              : 'd-none'
          }`}
        >
          <div
            className="filter-bar-width"
            data-tour="step-1"
            style={{
              position: 'absolute',
              height: 52,
              width: 930,
              backgroundColor: 'transparent',
              zIndex: -1,
            }}
          />

          {/* <div className={`filter-item1 ${!unitList.length ? 'cartridge-data-loading' : 'd-grid'}`}> */}
          <div className={`filter-item1 d-grid`}>
            <>
              <BasicPopover
                buttonText={selectedDevicesTextElement}
                anchorElement={anchorElements[0]}
                anchorElements={anchorElements}
                setAnchorElements={setAnchorElements}
              >
                <div className="mui-popover-content">
                  <UnitList
                    zephyrsPollutantData={zephyrsPollutantData}
                    accordionIndexes={accordionIndexes}
                    setAccordionIndexes={setAccordionIndexes}
                    isDataAvailabilityChartLoading={
                      isDataAvailabilityChartLoading
                    }
                    addUnitToAnalyticsList={addUnitToAnalyticsList}
                    selectedDeviceKeys={selectedDeviceKeys}
                  />
                </div>
              </BasicPopover>

              <BasicPopover
                buttonText={selectedPollutantsTextElement}
                anchorElement={anchorElements[1]}
                anchorElements={anchorElements}
                setAnchorElements={setAnchorElements}
              >
                <div className="mui-popover-content">
                  {!areAnyZephyrsSelected && (
                    <h4 className="list">No zephyrs selected</h4>
                  )}
                  {isPollutantDataEmpty && areAnyZephyrsSelected && (
                    <h4 className="list">No pollutants for selected unit/s</h4>
                  )}
                  {zephyrsPollutantData.pollutantData &&
                    zephyrsPollutantData.pollutantData.length > 0 &&
                    extractPolluntantUnitCards()}
                </div>
              </BasicPopover>

              <BasicPopover
                buttonText={selectedTimePeriodTextElement}
                anchorElement={anchorElements[2]}
                anchorElements={anchorElements}
                setAnchorElements={setAnchorElements}
              >
                <div className="mui-popover-content">
                  {timePeriods.periods.length > 0 &&
                    extractTimeAndAveragingPeriodsUnitCards(tPaP.timePeriods)}
                </div>
              </BasicPopover>

              <BasicPopover
                buttonText={selectedTimeAveragingElement}
                anchorElement={anchorElements[3]}
                anchorElements={anchorElements}
                setAnchorElements={setAnchorElements}
              >
                <div className="mui-popover-content">
                  {averagingPeriods.periods.length > 0 &&
                    extractTimeAndAveragingPeriodsUnitCards(
                      tPaP.averagingPeriods,
                      filteredZephyrs,
                    )}
                </div>
              </BasicPopover>
            </>
          </div>

          {unitList.length ? (
            <TaskBarCtas
              filteredZephyrs={zephyrsPollutantData.filteredZephyrs}
              pollutantData={zephyrsPollutantData.pollutantData}
              selectedTimePeriod={selectedTimePeriod}
              selectedTimeAveraging={selectedTimeAveraging}
              checkBeforeGenerateHistoricalData={
                checkBeforeGenerateHistoricalData
              }
              toggleSaveAndLoadViewModals={toggleSaveAndLoadViewModals}
              unitHistories={unitHistoriesList}
              resetViewsStateFromLocalStorage={resetViewsStateFromLocalStorage}
              hasSelectedZephyrAndPollutant={hasSelectedZephyrAndPollutant}
              userInfo={userInfo}
            />
          ) : null}
        </div>
      </div>
    );
  };

  return <div className={`filter-bar-1${userInfo.isPublic ? ' filter-bar-public' : ''}`}>{extractAnalyticsOptions()}</div>;
};

const TaskBarCtas = ({
  filteredZephyrs,
  pollutantData,
  selectedTimePeriod,
  selectedTimeAveraging,
  checkBeforeGenerateHistoricalData,
  toggleSaveAndLoadViewModals,
  unitHistoriesLoading,
  unitHistories,
  resetViewsStateFromLocalStorage,
  zephyrCoveragesList,
  hasSelectedZephyrAndPollutant,
  userInfo,
}: any) => {
  return (
    <div className="taskbar-ctas btn-group d-flex align-items-center justify-content-between">
      <div className='d-flex' style={{ gap: 32 }}>
        {hasSelectedZephyrAndPollutant().hasSelectedZephyr && hasSelectedZephyrAndPollutant().hasCheckedPollutant && selectedTimeAveraging && selectedTimePeriod ? <button
          type="button"
          className="prompt-button h-40 button-icon-right"
          onClick={checkBeforeGenerateHistoricalData}
          data-tour="step-2"
        >
          Run
          <img src={RunViewIcon} />
        </button> : <span className='select-data-to-proceed-text'>Select Data to Proceed</span>}
        <AnalysisTour />
      </div>
      {!userInfo.isPublic && <div className='save-load-wrapper' data-tour="step-8">
        {unitHistories.isLoaded && (
          <button
            className="prompt-button save-view-button h-40 button-icon-left"
            type="button"
            disabled={unitHistoriesLoading ? true : false}
            style={{
              marginRight: 15,
              cursor: unitHistoriesLoading ? 'auto' : 'pointer',
            }}
            onClick={() => {
              toggleSaveAndLoadViewModals('save');
            }}
          >
            <img src={SaveViewIcon} />
            Save View
          </button>
        )}

        <button
          className="prompt-button load-view-button h-40 button-icon-left"
          type="button"
          disabled={unitHistoriesLoading ? true : false}
          style={{
            cursor: unitHistoriesLoading ? 'auto' : 'pointer',
          }}
          onClick={() => {
            toggleSaveAndLoadViewModals('load');
          }}
        >
          <img src={LoadViewIcon} />
          Load View
        </button>
      </div>}

    </div>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  isZephyrsLoading: state.getZephyrs.loading,
  zephyrs: state.getZephyrs.zephyrs,
  isGetZephyrHistoricalDataLoading: state.getZephyrHistoricalData.loading,
  mappairPointMode: state.showAQMALayer.mappairPointModeOn,
  unitList: state.getZephyrs.zephyrs,
  curZephyr: state.setZephyr.zephyr,
  displayConfig: state.setDisplayConfig,
  timePeriods: state.setTimePeriods,
  averagingPeriods: state.setAveragingPeriods,
  queryStringParams: state.queryStringParams,
  dataConfig: state.dataConfig,
  unitHistoriesLoading: state.unitHistoriesOptions.isUnitHistoriesLoading,
  unitHistoriesList: state.unitHistoriesOptions.unitHistories,
  dseOn: state.unitHistoriesOptions.dseOn,
  bearerToken: state.auth.bearerToken,
  nullableUnits: state.unitHistoriesOptions.nullableUnits,
  toProceed: state.unitHistoriesOptions.toProceed,
  viewLoaded: state.unitHistoriesOptions.viewLoaded,
  isCartridgeDataLoading: state.unitHistoriesOptions.isCartridgeDataLoading,
  zephyrCoveragesList: state.unitHistoriesOptions.zephyrCoveragesList,
  isDataAvailabilityChartLoading:
    state.unitHistoriesOptions.isDataAvailabilityChartLoading,
  userInfo: state.auth.userInfo,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getZephyrData,
      setUnitHistoriesList,
      setUnitHistoriesLoading,
      increaseArrivedRequests,
      restartArrivedRequests,
      setNullableUnits,
      proceedWithAnalysis,
      setIsViewLoaded,
      setDraggedUnit,
      setZephyrInEditMode,
      setZephyrs,
      setZephyrCoveragesList,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(FilterBar1);
