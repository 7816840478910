import React, { memo, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState, UnitHistoriesOptions } from '../../utils/interface';
import { scatterChartLimit, widgetsGenerator } from '../../utils/consts';
import { setIsWidgetLibraryVisible } from '../../actions/dataAnalytics';
import { updateScatterChartNumber } from '../../utils/functions/dataAnalytics';

interface WidgetsLibraryMenuProps {
    layouts: any,
    setLayouts: Function,
    scatterChartsCounter: {
        deviceView: {
            gasWidget: number;
            particulateWidget: number;
            thpWidget: number;
            wsdWidget: number;
        };
        networkView: {
            networkGasWidget: number;
            networkParticulateWidget: number;
        };
    } | null,
    setScatterChartsCounter: Function;
};

const WidgetsLibraryMenu = memo(({ layouts, setLayouts, scatterChartsCounter, setScatterChartsCounter }: WidgetsLibraryMenuProps) => {
    const isWidgetLibraryVisible = useSelector((state: ReduxState) => state.unitHistoriesOptions.isWidgetLibraryVisible);
    const selectedView = useSelector((state: ReduxState) => state.selectedChartViewMode);
    const dispatch = useDispatch();
    const closeWidgetsLibrary = (stateSetter: boolean) => { dispatch(setIsWidgetLibraryVisible(stateSetter)) };
    const menuRef = useRef<HTMLDivElement>(null);

    const handleAddWidget = (widget: any) => {
        setLayouts((prevLayouts: any) => {
            return { ...prevLayouts, [selectedView]: [...layouts[selectedView], { ...widget.item }], [`${selectedView}Layout`]: [...layouts[`${selectedView}Layout`], { ...widget.layout }] }
        });
        handleCloseWidgetsLibraryMenu();
    };

    const handleCloseWidgetsLibraryMenu = () => {
        closeWidgetsLibrary(false);
    };

    const getScatterChartNumber = (widgetName: string) => {
        if (selectedView === 'deviceView' && scatterChartsCounter) return widgetName.includes('gasWidget') ? scatterChartsCounter[selectedView].gasWidget : widgetName.includes('particulateWidget') ? scatterChartsCounter[selectedView].particulateWidget : widgetName.includes('thpWidget') ? scatterChartsCounter[selectedView].thpWidget : widgetName.includes('wsdWidget') ? scatterChartsCounter[selectedView].wsdWidget : null;
        if (selectedView === 'networkView' && scatterChartsCounter) return widgetName.includes('networkGasWidget') ? scatterChartsCounter[selectedView].networkGasWidget : widgetName.includes('networkParticulateWidget') ? scatterChartsCounter[selectedView].networkParticulateWidget : null;
    };

    useEffect(() => {
        if (selectedView === 'comparisonView') closeWidgetsLibrary(false);
    }, [selectedView]);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (menuRef.current && !menuRef.current.contains(event.target as Node) && isWidgetLibraryVisible) handleCloseWidgetsLibraryMenu();
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [menuRef, isWidgetLibraryVisible]);


    const extractWidgetTypes = () => {
        return (widgetsGenerator(layouts) as any)[selectedView].map((widget: any) => {
            const { id, title, subTitle, imageURL, item: { i: widgetName } } = widget;
            const counter = getScatterChartNumber(widgetName);
            const isDisabled = counter === scatterChartLimit;
            return (
                <div key={id} className="widget-type-holder" onClick={() => { if (!isDisabled) { handleAddWidget(widget); updateScatterChartNumber(setScatterChartsCounter, selectedView, widgetName, true) } }} style={{ display: 'flex', alignItems: 'flex-start', gap: 16, padding: '8px 16px', borderBottom: '1px solid #F7FAFC', cursor: isDisabled ? 'initial' : 'pointer', opacity: isDisabled ? 0.6 : 1 }}>
                    <img src={require(`../../assets/icons/${imageURL}`)} alt={imageURL.split('.')[0]} style={{ padding: 16, background: '#EDF1F9', borderRadius: 4 }} height={84} />
                    <div className="widget-info-container" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', gap: 4, flex: '1 0 0', alignSelf: 'stretch' }}>
                        <p style={{ margin: 0, fontSize: 14, fontWeight: 600, color: "#1A202C" }}>{title}{counter !== null && <span style={{ marginLeft: 5, color: '#555' }}>({counter}/{scatterChartLimit})</span>}</p>
                        <p style={{ margin: 0, fontSize: 14, fontWeight: 400, color: "#4A5568" }}>{subTitle}</p>
                    </div>
                </div>
            );
        });
    };

    return (
        <>
            {isWidgetLibraryVisible && <div className="modal-wrapper"></div>}
            <div ref={menuRef} id="widgetsLibraryMenu" className='widgets-library-menu' style={{ position: 'fixed', right: 0, width: '35vw', height: '100vh', background: '#fff', borderTopLeftRadius: 5, borderBottomLeftRadius: 5, boxSizing: 'border-box', overflow: 'auto', transition: 'transform 0.5s ease', transform: isWidgetLibraryVisible ? 'translateX(0)' : 'translateX(100%)', zIndex: 999 }}>
                <div className="widgets-library-header" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 16, borderBottom: '1px solid #E2E8F0' }}>
                    <p style={{ margin: 0, fontSize: 16, fontWeight: 600, color: "#1A202C" }}>Add Widget</p>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="13"
                        height="13"
                        viewBox="0 0 24 24"
                        fill="#000"
                        cursor={'pointer'}
                        onClick={handleCloseWidgetsLibraryMenu}
                    >
                        <path d="M23 20.168l-8.185-8.187 8.185-8.174-2.832-2.807-8.182 8.179-8.176-8.179-2.81 2.81 8.186 8.196-8.186 8.184 2.81 2.81 8.203-8.192 8.18 8.192z"></path>
                    </svg>
                </div>
                <div className="widget-types-container">
                    {extractWidgetTypes()}
                </div>
            </div>
        </>
    )
});



export default WidgetsLibraryMenu;