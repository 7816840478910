import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Modal from '@mui/material/Modal';
import React, { useState } from 'react';
import CloseIcon from '../../../assets/images/icon-close.svg';
import KebabIcon from '../../../assets/images/icon-kebab-dark.svg';
import MaximiseIcon from '../../../assets/images/icon-maximize.svg';
import RemoveIcon from '../../../assets/images/remove.svg';
import { styleModalWithCta } from '../../../utils/styles';
import { thresholdLimitsInitState } from '../../../reducers/dataAnalytics';
import { ThresholdLimits, ThresholdsList } from '../../../utils/interface';
import { thresholdMainItemStyles, thresholdMenuItemStyles } from '../../../utils/consts';
import { Tooltip } from '@mui/material';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

function KebabMenuCta({
  setIsThresholdModalOpen,
  thresholdLimits,
  setThresholdsList,
  type,
  selectedOption,
  setSelectedOption,
  isComparisonView,
  onRemoveWidget,
}: {
  setIsThresholdModalOpen: Function;
  thresholdLimits?: ThresholdLimits,
  setThresholdsList?: Function;
  type?: string;
  selectedOption?: string;
  setSelectedOption?: Function;
  isComparisonView?: boolean;
  onRemoveWidget?: (type: string) => void;
}) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [menu2AnchorEl, setMenu2AnchorEl] = useState<null | HTMLElement>(null);
  const [statisticsMenuAnchorEl, setStatisticsMenuAnchorEl] = useState<null | HTMLElement>(null);
  const openChartMenu = Boolean(anchorEl);
  const openSubMenu = Boolean(menu2AnchorEl);
  const openStatisticsMenu = Boolean(statisticsMenuAnchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMainMenuClosing = () => {
    setAnchorEl(null);
  };

  const handleClose = () => {
    setMenu2AnchorEl(null);
  };

  const handleStatisticsClose = () => {
    setStatisticsMenuAnchorEl(null);
  }

  const handleMenu2Open = (event: React.MouseEvent<HTMLElement>) => {
    setMenu2AnchorEl(event.currentTarget);
  };

  const handleStatisticsMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setStatisticsMenuAnchorEl(event.currentTarget);
  };

  const updateThresholdsList = (value: ThresholdLimits) => {
    setThresholdsList!((prevState: ThresholdsList) => ({
      ...prevState,
      [type as keyof ThresholdsList]: value,
    }));
  };

  const handleThresholdToggling = () => {
    const updatedThresholds = {
      ...thresholdLimits,
      hidden: !(thresholdLimits as ThresholdLimits).hidden,
    };
    updateThresholdsList(updatedThresholds as ThresholdLimits);
  };

  const thresholdLinesAdded = () => {
    return thresholdLimits && (typeof thresholdLimits.thresholds.lowerThreshold.value === 'number' || typeof thresholdLimits.thresholds.upperThreshold.value === 'number');
  };

  const openStatistics = () => {
    return (
      <Menu
        anchorEl={statisticsMenuAnchorEl}
        open={openStatisticsMenu}
        onClose={handleStatisticsClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        style={{ top: -8 }}
        PaperProps={{
          style: {
            borderRadius: 0,
            marginLeft: 1,
            marginTop: 0
          },
        }}
      >
        <MenuItem>
          <label className="radio" htmlFor={`radio-5med95`} style={{ margin: 0, color: '#4a5568', fontWeight: 500 }}>
            5% / Median / 95%
            <input
              type="radio"
              id={`radio-5med95`}
              name="radio"
              checked={selectedOption === '5med95'}
              value={'5med95'}
              onChange={(event: any) => {
                setSelectedOption!(event.target.value);
              }}
            />
            <span className="checkmark" />
          </label>
        </MenuItem>
        <MenuItem>
          <label className="radio" htmlFor={`radio-minMeanMax`} style={{ margin: 0, color: '#4a5568', fontWeight: 500 }}>
            Min / Mean / Max
            <input
              type="radio"
              id={`radio-minMeanMax`}
              name="radio"
              checked={selectedOption === 'minMeanMax'}
              value={'minMeanMax'}
              onChange={(event: any) => {
                setSelectedOption!(event.target.value);
              }}
            />
            <span className="checkmark" />
          </label>
        </MenuItem>
      </Menu>
    )
  }

  const subMenuItems = () => {
    const linesExist = thresholdLinesAdded();
    return (
      <Menu
        anchorEl={menu2AnchorEl}
        open={openSubMenu}
        onClose={() => { handleClose(); handleStatisticsClose(); }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: !isComparisonView ? 'right' : 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: !isComparisonView ? 'left' : 'right',
        }}
        style={{ top: -8 }}
        PaperProps={{
          style: {
            borderRadius: 0,
            marginLeft: !isComparisonView ? 1 : -1,
            marginTop: 0
          },
        }}
      >
        {!linesExist && <MenuItem
          className='threshold-menu-item'
          onClick={() => { setIsThresholdModalOpen(true); handleClose(); handleMainMenuClosing(); }}
          sx={thresholdMenuItemStyles}
        >
          <span>Add</span>
          <span className="chevron-icon threshold add"></span>
        </MenuItem>}
        {linesExist && <div>
          <MenuItem
            onClick={handleThresholdToggling}
            sx={thresholdMenuItemStyles}
          >
            <span>{thresholdLimits?.hidden ? 'Show' : 'Hide'}</span>
            <span className={`chevron-icon threshold ${thresholdLimits?.hidden ? 'show' : 'hide'}`}></span>
          </MenuItem>
          <MenuItem
            onClick={() => { setIsThresholdModalOpen(true); handleClose(); handleMainMenuClosing(); }}
            sx={thresholdMenuItemStyles}
          >
            <span>Edit</span>
            <span className="chevron-icon threshold edit"></span>
          </MenuItem>
          <MenuItem
            onClick={() => { handleClose(); updateThresholdsList(thresholdLimitsInitState); handleMainMenuClosing(); }}
            sx={thresholdMenuItemStyles}
          >
            <span>Remove</span>
            <span className="chevron-icon threshold remove"></span>
          </MenuItem>
        </div>

        }
      </Menu>
    );
  };

  const handleRemoveWidget = () => {
    if (onRemoveWidget && type) {
      handleMainMenuClosing();
      onRemoveWidget(type);
    };
  }

  return (
    <div>
      <button
        type="button"
        className="buttonStyle no-style show-on-hover"
        aria-controls={openChartMenu ? 'basic-menu' : undefined}
        aria-expanded={openChartMenu ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <img src={KebabIcon} alt="menu" />
      </button>
      <Menu
        anchorEl={anchorEl}
        open={openChartMenu}
        onClose={handleMainMenuClosing}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        PaperProps={{
          style: {
            borderRadius: 0,
          },
        }}
      >
        <MenuItem
          onClick={handleMenu2Open}
          style={{ fontSize: 14, width: 165, paddingTop: 3, paddingBottom: 3 }}
          sx={{ ...thresholdMainItemStyles, background: openSubMenu ? '#f5f5f5' : 'initial' }}
        >
          <span>Threshold line</span>
          <span className="chevron-icon threshold line"></span>
        </MenuItem>
        {type && type.includes('network') && (
          <MenuItem
            onClick={handleStatisticsMenuOpen}
            style={{
              fontSize: 14,
              width: 165,
              paddingTop: 3,
              paddingBottom: 3,
            }}
            sx={{ ...thresholdMainItemStyles, background: openStatisticsMenu ? '#f5f5f5' : 'initial' }}
          >
            <span>Statistics</span>
            <span className="chevron-icon threshold line"></span>
          </MenuItem>
        )}
        {localStorage.getItem('isPublic') !== 'true' && <MenuItem
          onClick={handleRemoveWidget}
          style={{ fontSize: 14, width: 165, paddingTop: 3, paddingBottom: 3 }}
        >
          <span style={{ display: 'flex', alignItems: 'center', gap: '2px', color: '#4a5568', fontWeight: 400 }}>
            <img src={RemoveIcon} alt="remove" />
            Remove widget
          </span>
        </MenuItem>}
      </Menu>
      {openSubMenu && subMenuItems()}
      {openStatisticsMenu && openStatistics()}
    </div>
  );
}

function ModalWithCta({ modalChart = null }: { modalChart?: any | null }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleOpenChartModal = () => {
    setIsModalOpen(true);
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  if (!modalChart) return null;

  return (
    <div>
      <button
        type="button"
        className="buttonStyle no-style show-on-hover" onClick={handleOpenChartModal}>
        <img src={MaximiseIcon} alt="expand" />
      </button>
      <Modal open={isModalOpen} onClose={handleCloseModal}>
        <Box sx={{ ...styleModalWithCta }}>
          <button
            className="closeButtonStyle no-style"
            type="button"
            onClick={handleCloseModal}
          >
            <img src={CloseIcon} alt="close modal" />
          </button>
          {modalChart}
        </Box>
      </Modal>
    </div>
  );
}

export function CustomMenu({ onRemoveWidget, type }: { onRemoveWidget: (type: string) => void, type: string }) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return type === 'map' && localStorage.getItem('isPublic') === 'true' ? null : (
    <>
      <button
        type="button"
        className="buttonStyle no-style show-on-hover"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <img src={KebabIcon} alt="menu" />
      </button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        style={{ top: -8 }}
        PaperProps={{
          style: {
            borderRadius: 0,
            marginLeft: 1,
            marginTop: 0
          },
        }}
      >
        {localStorage.getItem('isPublic') !== 'true' && <MenuItem
          onClick={() => onRemoveWidget(type)}
          style={{ fontSize: 14, width: 165, paddingTop: 3, paddingBottom: 3 }}
        >
          <span style={{ display: 'flex', alignItems: 'center', gap: '2px', color: "#4a5568", fontWeight: 400 }}>
            <img src={RemoveIcon} alt="remove" />
            Remove widget
          </span>
        </MenuItem>}
      </Menu>
    </>
  )
}

function TitleBarCtas({
  modalChart = null,
  setIsThresholdModalOpen = null,
  thresholdLimits,
  setThresholdsList,
  type,
  selectedOption,
  setSelectedOption,
  isComparisonView,
  onRemoveWidget,
  useCustomMenu = false,
}: {
  modalChart?: any | null;
  setIsThresholdModalOpen?: null | Function;
  thresholdLimits?: ThresholdLimits,
  setThresholdsList?: Function;
  type?: string;
  selectedOption?: string;
  setSelectedOption?: Function;
  isComparisonView?: boolean;
  onRemoveWidget?: (type: string) => void;
  useCustomMenu?: boolean;
}) {
  return (
    <div className="d-flex justify-content-end grid-gap8px custom-modebar-actions" data-tour="step-6">
      {setIsThresholdModalOpen ? (
        <KebabMenuCta
          setIsThresholdModalOpen={setIsThresholdModalOpen}
          thresholdLimits={thresholdLimits}
          setThresholdsList={setThresholdsList}
          type={type}
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
          isComparisonView={isComparisonView}
          onRemoveWidget={onRemoveWidget}
        />
      ) : null}

      {onRemoveWidget && useCustomMenu && (
        <CustomMenu onRemoveWidget={onRemoveWidget} type={type as string} />
      )}
      <ModalWithCta modalChart={modalChart} />
    </div>
  );
}

export const ChartTitleBar = ({
  title,
  HasMetStation,
  chartType,
  zType,
  children = null,
  setIsThresholdModalOpen,
  className = '',
  customModal,
  thresholdLimits,
  setThresholdsList,
  type,
  isComparisonView,
  selectedOption,
  setSelectedOption,
  isDragging,
  draggedItem,
  isWidgetHovered,
  isNetworkMap,
  onRemoveWidget,
  useCustomMenu = false,
}: {
  title: string | string[];
  HasMetStation?: number;
  chartType?: string;
  zType?: number;
  children?: any | null;
  setIsThresholdModalOpen?: Function;
  className?: string;
  customModal?: any;
  thresholdLimits?: ThresholdLimits,
  setThresholdsList?: Function;
  type?: string;
  isComparisonView?: boolean;
  selectedOption?: string;
  setSelectedOption?: Function;
  isDragging?: boolean;
  draggedItem?: string | null;
  isWidgetHovered?: boolean;
  isNetworkMap?: boolean;
  onRemoveWidget?: (type: string) => void;
  useCustomMenu?: boolean;
}) => {
  const chartBadge = !isComparisonView && type !== 'networkViewPollutantSummary' && !type?.includes('networkViewPollutantSummary') && (chartType || typeof zType === 'number' || type?.toLowerCase().includes('network')) ? <span className='chart-badge'>{type?.toLowerCase().includes('sourceapportionment') ? 'Modelled (Beta)' : type?.toLowerCase().includes('network') ? selectedOption === 'minMeanMax' ? 'Min / Mean / Max' : '5% / Median / 95%' : typeof zType === 'number' ? zType === 0 ? 'Zephyr' : 'Modelled' : chartType === 'th' ? HasMetStation ? 'Met Station' : zType === 0 ? 'Zephyr' : 'Modelled' : HasMetStation ? 'Met Station' : 'Modelled'}</span> : null;
  const getReformattedName = () => {
    return title === 'NO2 Sources' ? <>NO<sub>2</sub> Sources</> : title === 'PM2.5 Sources' ? <>PM<sub>2.5</sub> Sources</> : Array.isArray(title)
      ? title[1] === '25'
        ? <>{title[0]}<sub>2.5</sub></>
        : <>{title[0]}<sub>{title[1]}</sub></>
      : title;
  };
  return (
    <div className={className ? className : 'chart-title-bar'}>
      <div className="d-flex justify-content-between align-items-center">
        {isWidgetHovered && <div style={{ position: 'relative', width: 22, height: 22, cursor: isDragging ? 'grabbing' : 'grab' }} className="drag-handle">
          <Tooltip
            title="Drag widget"
            arrow
            placement="bottom"
            componentsProps={{
              tooltip: {
                sx: {
                  fontSize: '12px',
                },
              },
            }}
          >
            <DragIndicatorIcon fontSize='large' style={{ position: 'absolute', top: -4 }} />
          </Tooltip>
        </div>}
        <h3 style={{ height: isNetworkMap ? 16 : 'initial' }}>{getReformattedName()}{chartBadge}</h3>
        {customModal || (
          <TitleBarCtas
            modalChart={children}
            setIsThresholdModalOpen={setIsThresholdModalOpen}
            thresholdLimits={thresholdLimits}
            setThresholdsList={setThresholdsList}
            type={type}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
            isComparisonView={isComparisonView}
            onRemoveWidget={onRemoveWidget}
            useCustomMenu={useCustomMenu}
          />
        )}
      </div>
    </div>
  );
};
