import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { closeAside, openAside } from '../../actions/aside';
import {
  setIsCartridgeDataLoading,
  setIsViewLoaded,
  setThresholdLimits,
  setUnitHistoriesList,
  setZephyrCoveragesList,
} from '../../actions/dataAnalytics';
import { setThresholds } from '../../actions/thresholdsActions';
import ESLogo from '../../assets/images/logo-grey.png';
import { thresholdLimitsInitState } from '../../reducers/dataAnalytics';

import { comparisonViewCSVGenerator, deviceCSVGenerator, summaryTableGenerator } from '../../utils/functions/saDataPackager';

import {
  Pollutant,
  ReduxState,
  ThresholdLimits,
  ThresholdsList,
  UserInfo,
  Zephyr,
} from '../../utils/interface';
import AnalyticsProgressBar from '../shared_components/AnalyticsProgressBar';
import { FilterBar2 } from '../view_components/FilterBar/FilterBar2';
import Modal from '../shared_components/Modal';

import FeedbackFormModal from '../shared_components/FeedbackFormModal';

import ReactGridLayout, {
  getSelectedZephyrObj,
  getZephyrType,
} from '../view_components/ReactGridLayout/ReactGridLayout';
import { ErrorBoundary } from 'react-error-boundary';
import JSZip from 'jszip';
import { setChartViewMode, ChartViewMode } from '../../actions/chartViewMode';

interface DataAnalyticsContainerProps {
  zephyrs: Zephyr[];
  unitHistories: any;
  unitHistoriesLoading: boolean;
  thresholdLimits: ThresholdLimits;
  setThresholdLimits: Function;
  asideOn: boolean;
  viewLoaded: boolean;
  setIsViewLoaded: Function;
  openAside: Function;
  closeAside: Function;
  userInfo: UserInfo;
  layouts: any;
  setLayouts: Function;
  setScatterChartsCounter: Function;
}

export interface Series {
  name?: string;
  type?: string;
  lineStyle?: any;
  showSymbol?: boolean;
  data?: any[];
}

export interface Options {
  headers: string[];
  timeStamps: string[];
  series: Series[];
  yAxis: any[];
  yMax: number;
  color?: string[];
  slots?: string[];
}

export interface DefraWho {
  isSelected: boolean;
  name: string;
}

const sliderInitState = {
  start: 0,
  end: 100,
};

export const CSVButtonStyles = {
  display: 'flex',
  alignItems: 'center',
  width: 40,
  height: 32,
  padding: '16px 14px 13px 16px',
  border: 0,
  borderRadius: 3,
  backgroundColor: '#e1e4e6',
  cursor: 'pointer',
};

function FallbackError() {
  useEffect(() => {
    const timer = setTimeout(() => {
      window.location.reload();
    }, 1500)

    return () => clearTimeout(timer)
  }, [])

  return (
    <div className="error-message-container">
      <p>Oops, something went wrong...</p>
      <p>We're redirecting you now...</p>
    </div>
  )
}

const DataAnalyticsContainer = ({
  zephyrs,
  unitHistories,
  unitHistoriesLoading,
  thresholdLimits,
  setThresholdLimits,
  viewLoaded,
  userInfo,
  layouts,
  setLayouts,
  setScatterChartsCounter,
}: DataAnalyticsContainerProps) => {
  const [selectedPollutants, setSelectedPollutants] = useState<Pollutant[]>([]);
  const [selectedPollutant, setSelectedPollutant] = useState<Pollutant | null>(
    null,
  );
  const [selectedWeatherZephyr, setSelectedWeatherZephyr] = useState<any>(null);
  const [selectedZephyrObj, setSelectedZephyrObj] = useState<any>(null);
  const [selectedAveragingPeriod, setSelectedAveragingPeriod] = useState<any>(
    null,
  );
  const [chartOptions, setChartOptions] = useState<any>(null);
  const [legendsState, setLegendsState] = useState<any>({});
  const [sliderState, setSliderState] = useState<{
    start: number;
    end: number;
  }>(sliderInitState);

  // const [selectedView, setSelectedView] = React.useState('deviceView');
  const selectedView = useSelector((state: ReduxState) => state.selectedChartViewMode);
  const dispatch = useDispatch();

  const setSelectedView = (newView: ChartViewMode) => {
    dispatch(setChartViewMode(newView));
  };
  const [networkViewUnitList, setNetworkViewUnitLIst] = React.useState<any[]>(
    [],
  );
  const [comparisonViewZephyrs, setComparisonViewZephyrs] = useState<{ name: string, id: string, HasMetStation: number | null }[]>([]);
  const [loadingComparison, setLoadingComparison] = useState(true);
  const [defaultComparisonView, setDefaultComparisonView] = useState<any>(null);
  const [defaultComparisonViewLayout, setDefaultComparisonViewLayout] = useState<{ uid: string, name: string, i: string }[] | null>(null);

  const thresholdsList = useSelector((state: ReduxState) => state.thresholds);
  // const dispatch = useDispatch();
  const setThresholdsList = (stateSetter: any) => {
    dispatch(setThresholds(stateSetter));
  };

  useEffect(() => {
    if (unitHistories && unitHistories.historiesList && unitHistories.historiesList.length > 0) {
      localStorage.setItem('plotlyLegends', JSON.stringify({
        gases: null,
        particulate: null,
        thpChartPlotly: null,
        windSpeedDirChartPlotly: null
      }))
    }
  }, [unitHistories]);

  useEffect(() => {
    if (unitHistories && 'historiesList' in unitHistories) {
      if (
        !selectedWeatherZephyr ||
        !unitHistories.historiesList.find(
          (unit: any) =>
            +(Object.keys(unit) as any)[0] === +selectedWeatherZephyr.id,
        )
      ) {
        setSelectedWeatherZephyr(getSelectedZephyrs()[0]);
      }
      unitHistories.historiesList.length > 1 ? setComparisonViewZephyrs([getSelectedZephyrs()[0], getSelectedZephyrs()[1]]) : setComparisonViewZephyrs([getSelectedZephyrs()[0]]);
      const pollutants = [...getSelectedPollutants()];
      pollutants[0].isSelected = true;
      setSelectedPollutants([...pollutants]);
      setSelectedPollutant(pollutants[0]);
      setLegendsState({});
      setSliderState(sliderInitState);
    }
  }, [unitHistories]);

  useEffect(() => {
    if (!selectedWeatherZephyr || zephyrs?.length === 0) return;

    if (unitHistories.historiesList && unitHistories.historiesList.length > 0 && isUnitHistoriesListUpdated()) {
      const { selectedZephyr, selectedAveragingPeriod } = getSelectedZephyrObj(
        unitHistories,
        selectedWeatherZephyr ? selectedWeatherZephyr : getSelectedZephyrs()[0],
        zephyrs,
      );
      setSelectedAveragingPeriod(selectedAveragingPeriod);
      setSelectedZephyrObj(selectedZephyr);
    }
  }, [unitHistories, zephyrs, selectedWeatherZephyr]);

  useEffect(() => {
    const activateView = (viewType: string) => {
      switch (selectedView) {
        case 'deviceView':
          break;
        case 'networkView':
          const allSelectedKeys = unitHistories.historiesList.map(
            (obj: any) => +Object.keys(obj)[0],
          );
          const filteredZephyrsToBeSelected = zephyrs
            .filter((zephyr) => allSelectedKeys.includes(zephyr.zNumber))
            .map((zephyr) => ({ ...zephyr, isSelected: true })); // Mapping to update isSelected
          setNetworkViewUnitLIst(filteredZephyrsToBeSelected);
          break;
        case 'comparisonView':
          break;
      }
    };

    if (zephyrs?.length === 0) return;
    if (unitHistories.historiesList && unitHistories.historiesList.length > 0) {
      activateView(selectedView);
    }
  }, [selectedView, unitHistories, zephyrs]);

  useEffect(() => {
    // Reset chart state
    if (unitHistoriesLoading === true && chartOptions) setChartOptions(null);
  }, [unitHistoriesLoading]);

  useEffect(() => {
    const pollutants: { [key: string]: ThresholdLimits } = {};
    for (const pollutant of selectedPollutants) {
      const { dataIdentifier } = pollutant;
      if (!thresholdsList.hasOwnProperty(dataIdentifier)) {
        pollutants[dataIdentifier] = {
          thresholds: {
            lowerThreshold: {
              title: '',
              value: null,
            },
            upperThreshold: {
              title: '',
              value: null,
            }
          },
          hidden: false
        }
      }
    }

    if (Object.keys(pollutants).length) setThresholdsList((list: any) => ({ ...list, ...pollutants }));
  }, [selectedPollutants]);

  useEffect(() => {
    if (layouts && !userInfo.isPublic) {
      const currentThresholdList = Object.keys(thresholdsList);
      const { deviceViewLayout, networkViewLayout } = layouts;
      const concatenatedLayouts = [...deviceViewLayout, ...networkViewLayout];
      const layoutsObject: { [key: string]: ThresholdLimits } = {};
      concatenatedLayouts.forEach((layout: { uid: string, name: string, i: string, threshold: boolean }) => {
        const { name: layoutName, threshold } = layout;
        if (threshold && !currentThresholdList.includes(layoutName)) {
          layoutsObject[layoutName] = {
            thresholds: {
              lowerThreshold: {
                title: '',
                value: null,
              },
              upperThreshold: {
                title: '',
                value: null,
              }
            },
            hidden: false
          }
        }
      });
      if (Object.keys(layoutsObject).length) setThresholdsList((list: any) => ({ ...list, ...layoutsObject }))
    }
  }, [layouts]);


  const getSelectedPollutants = () => {
    const { historiesList } = unitHistories;
    const unitKey = Object.keys(historiesList[0])[0];
    const { selectedPollutants } = historiesList[0][unitKey];
    return selectedPollutants;
  };

  const getSelectedZephyrs = () =>
    unitHistories.historiesList?.map((unit: any) => ({
      name: (Object.values(unit) as any)[0].name,
      id: (Object.keys(unit) as any)[0],
      HasMetStation: (Object.values(unit) as any)[0].HasMetStation
    }));

  const handleWeatherZephyrSwitching = (zephyr: { name: string, id: string, HasMetStation: number | null }) => {
    if (+zephyr.id !== +selectedWeatherZephyr.id)
      setSelectedWeatherZephyr(zephyr);
  };

  const getCSVData = () => {
    const currentDate = moment().format('DD/MM/YYYY');
    const formatDateStringWithoutSeperator = (datetime: moment.MomentInput) => {
      return moment(datetime, 'YYYYMMDDHHmm').format('DD/MM/YYYY HH:mm');
    };
    const getTimePeriod = (data: {
      selectedDate: { [x: string]: moment.MomentInput };
    }) =>
      `${formatDateStringWithoutSeperator(
        data.selectedDate['start'],
      )} - ${formatDateStringWithoutSeperator(data.selectedDate['end'])}`;

    const timestampEnding = (datetimeString: moment.MomentInput) => {
      // Parse the datetime string (assuming format 'YYYYMMDDHHMM')
      const momentDate = moment(datetimeString, 'YYYYMMDDHHmm');
      // Format the date to include local timezone information
      return momentDate.local().format('DD/MM/YYYY HH:mm');
    };

    const csvDataArray: any = [];

    if (selectedView === 'deviceView') {
      csvDataArray.push([
        `Averaging time data supplied by EarthSense on ${currentDate}`,
      ]);
    } else {
      csvDataArray.push([`Data supplied by EarthSense on ${currentDate}`]);
    }

    unitHistories?.historiesList?.forEach(
      (unitHistoryItem: any) => {
        for (const id in unitHistoryItem) {
          if (
            unitHistoryItem.hasOwnProperty(id) &&
            selectedZephyrObj !== null &&
            'zNumber' in selectedZephyrObj &&
            +id === selectedZephyrObj.zNumber
          ) {
            const unitObj = unitHistoryItem[id];
            const unitType = getZephyrType(unitObj['zType']);

            if (selectedView === 'deviceView') {
              const zephyr = zephyrs.find((unit: Zephyr) => unit.zNumber == +id);
              const slotA_BIC = zephyr?.slotA_SN;
              const slotB_BIC = zephyr?.slotB_SN;
              // Add data header
              csvDataArray.push(
                // `Source,${unitObj['name']}`,
                ['Source', `${unitType}`],
                ['ID', `${unitType} (${id.toString()})`],
                ['Site Name', `${unitObj['name']}`],
                ['Time Period', `${getTimePeriod(unitObj)}`],
                [
                  'Timestamp',
                  `${timestampEnding(
                    unitObj.selectedDate['end'],
                  )} Local Time Zone Code Time Ending`,
                ],
                [
                  'Averaging Period',
                  `${unitObj['selectedAveragingPeriod'].labelHTML}`,
                ],
              );
              if (slotA_BIC) csvDataArray.push(['Slot A BIC', `${slotA_BIC}`])
              if (slotB_BIC) csvDataArray.push(['Slot B BIC', `${slotB_BIC}`])
              csvDataArray.push([]); // Adding an empty row
              // Exctract titles and data
              const { tableData, tableTitles } = deviceCSVGenerator(unitHistories, unitObj, +id, zephyrs, userInfo.isPublic) as { tableData: any[], tableTitles: string[] };
              csvDataArray.push(tableTitles, ...tableData);
            } else {
              csvDataArray.push(
                ['Time Period', `${getTimePeriod(unitObj)}`],
                [
                  'Timestamp',
                  `${timestampEnding(
                    unitObj.selectedDate['end'],
                  )} Local Time Zone Code Time Ending`,
                ],
                [
                  'Averaging Period',
                  `${unitObj['selectedAveragingPeriod'].labelHTML}`,
                ],
                [], // Adding an empty row
              );

              // Add data header2
              const { tableData, tableTitles } = summaryTableGenerator(unitHistories, zephyrs, true) as { tableData: any[], tableTitles: { title: string }[] | string[] }; // data and titles need to be in redux
              csvDataArray.push(tableTitles, ...tableData);
            }

            csvDataArray.push([], []); // Adding an empty rows
          }
        }
      },
    );
    csvDataArray.join('\n');
    return csvDataArray;
  };

  const getComparisonViewCSV = () => {
    const currentDate = moment().format('DD/MM/YYYY');
    const formatDateStringWithoutSeperator = (datetime: moment.MomentInput) => {
      return moment(datetime, 'YYYYMMDDHHmm').format('DD/MM/YYYY HH:mm');
    };
    const getTimePeriod = (data: {
      selectedDate: { [x: string]: moment.MomentInput };
    }) =>
      `${formatDateStringWithoutSeperator(
        data.selectedDate['start'],
      )} - ${formatDateStringWithoutSeperator(data.selectedDate['end'])}`;

    const timestampEnding = (datetimeString: moment.MomentInput) => {
      // Parse the datetime string (assuming format 'YYYYMMDDHHMM')
      const momentDate = moment(datetimeString, 'YYYYMMDDHHmm');
      // Format the date to include local timezone information
      return momentDate.local().format('DD/MM/YYYY HH:mm');
    };

    let csvConstantData: string[][] = [[
      `Averaging time data supplied by EarthSense on ${currentDate}`,
    ]];
    const { historiesList } = unitHistories;
    const { selectedAveragingPeriod: { labelHTML } } = Object.values(historiesList[0])[0] as any;
    const formattedAveraginOption = `${labelHTML.split(' ')[0]}${labelHTML.toLowerCase().includes('min') ? 'min' : 'hr'}`;
    const {
      selectedDate: { start, end },
    } = historiesList[0][Object.keys(historiesList[0])[0]];
    const selectedZephyrNumbers = comparisonViewZephyrs.map((z: any) => z.id);
    const commonUnits = historiesList.filter((unit: any) => selectedZephyrNumbers.includes(Object.keys(unit)[0]));
    const zip = new JSZip();
    commonUnits.forEach((unit: any, i: number) => {
      if (i > 0) csvConstantData = [csvConstantData[0]]; // reset if selected zephyrs are more than 1
      const unitId = Object.keys(unit)[0];
      const unitObj = unit[unitId];
      const unitType = getZephyrType(unitObj['zType']);
      const zephyr = zephyrs.find((unit: Zephyr) => unit.zNumber == +unitId);
      const slotA_BIC = zephyr?.slotA_SN;
      const slotB_BIC = zephyr?.slotB_SN;
      csvConstantData.push(
        ['Source', `${unitType}`],
        ['ID', `${unitType} (${unitId.toString()})`],
        ['Site Name', `${unitObj['name']}`],
        ['Time Period', `${getTimePeriod(unitObj)}`],
        [
          'Timestamp',
          `${timestampEnding(
            unitObj.selectedDate['end'],
          )} Local Time Zone Code Time Ending`,
        ],
        [
          'Averaging Period',
          `${unitObj['selectedAveragingPeriod'].labelHTML}`,
        ],
      );
      if (slotA_BIC) csvConstantData.push(['Slot A BIC', `${slotA_BIC}`])
      if (slotB_BIC) csvConstantData.push(['Slot B BIC', `${slotB_BIC}`])
      csvConstantData.push([]); // Adding an empty row

      const { tableData, tableTitles } = comparisonViewCSVGenerator(unit, zephyrs);

      const csvContentArray = [...csvConstantData.map((row: any) => row.join(',')), tableTitles.join(','), ...tableData.map(row => row.join(','))];
      const csvContent = csvContentArray.join('\n');

      // Add UTF-8 BOM (Byte Order Mark) at the start of the CSV content
      const utf8Bom = '\uFEFF';
      const utf8CsvContent = utf8Bom + csvContent;

      // Create a blob with UTF-8 encoding
      const utf8Blob = new Blob([utf8CsvContent], { type: 'text/csv;charset=utf-8;' });

      // Add the blob to the zip file
      zip.file(`${unitId}_${start}_${end}_${formattedAveraginOption}.csv`, utf8Blob);
    })
    return zip.generateAsync({ type: 'blob' });
  };

  const isUnitHistoriesListUpdated = () => {
    return unitHistories.historiesList.find(
      (zephyr: any) => +(Object.keys(zephyr) as any)[0] === +selectedWeatherZephyr.id,
    );
  };

  const handleZephyrsTogglingOnComparisonView = (zephyr: { name: string, id: string, HasMetStation: number | null }) => {
    if (comparisonViewZephyrs.length === 1 && +zephyr.id === +comparisonViewZephyrs[0].id) return;
    const { id: zephyrZNumber } = zephyr;
    setComparisonViewZephyrs((zephyrList: { name: string, id: string, HasMetStation: number | null }[]) => {
      const isZephyrIn = zephyrList.find((z: { name: string, id: string, HasMetStation: number | null }) => z.id === zephyrZNumber);
      return isZephyrIn ? [...zephyrList.filter((z: { name: string, id: string, HasMetStation: number | null }) => z.id !== zephyrZNumber)] : [...zephyrList, zephyr];
    })
  };

  const handleFilterBarUpdates = (view: string, zephyr: { name: string, id: string, HasMetStation: number | null }) => {
    switch (view) {
      case 'deviceView':
        handleWeatherZephyrSwitching(zephyr);
        break;
      case 'comparisonView':
        handleZephyrsTogglingOnComparisonView(zephyr);
        break;
      default:
        throw new Error("There is an error with your filter bar");
    }
  };

  useEffect(() => {
    if (unitHistories.historiesList && Array.isArray(unitHistories.historiesList) && unitHistories.historiesList.length > 0) {
      const { historiesList } = unitHistories;
      const { selectedPollutants } = historiesList[0][Object.keys(historiesList[0])[0]];
      let comparisonlayouts: { uid: string, name: string, i: string }[] = [];
      let items: { w: number, h: number, x: number, y: number, moved: boolean, static: boolean, i: string, maxH: number, minH: number, minW: number, isRemoved: boolean }[] = [];
      let counter = 0;
      const defaultItems = [];

      for (const pollutant of selectedPollutants) {
        const pollutantWidgetName = `${pollutant.dataIdentifier}Widget`;
        const pollutantWidget = layouts && layouts.comparisonView ? layouts.comparisonView.find((p: any) => p.i === pollutantWidgetName) : null;
        let item = {
          w: pollutantWidget ? pollutantWidget.w : 12,
          h: pollutantWidget ? pollutantWidget.h : 8,
          x: pollutantWidget ? pollutantWidget.x : 0,
          y: pollutantWidget ? pollutantWidget.y : counter * 8,
          moved: false,
          static: false,
          i: pollutantWidgetName,
          maxH: 18,
          minH: 4,
          minW: 4,
          isRemoved: pollutantWidget ? Boolean(pollutantWidget.isRemoved) : false,
        };
        let layout = {
          uid: pollutantWidgetName,
          name: pollutant.dataIdentifier,
          i: pollutantWidgetName,
        };
        defaultItems.push({ w: 12, h: 8, x: 0, y: counter * 8, moved: false, static: false, i: pollutantWidgetName, maxH: 18, minH: 4, minW: 4, isRemoved: false })
        items.push(item);
        comparisonlayouts.push(layout);
        counter++;
      }
      // update default comparison view/layout
      setDefaultComparisonView(defaultItems);
      setDefaultComparisonViewLayout(comparisonlayouts);

      // Set loading to true before updating states
      setLoadingComparison(true);
      setLayouts((prevLayouts: any) => {
        return { ...prevLayouts, comparisonView: items, comparisonViewLayout: comparisonlayouts }
      })
    }
  }, [unitHistories]);

  useEffect(() => {
    // Set loading to false when both arrays are updated
    if (layouts && layouts.hasOwnProperty('comparisonView') && layouts.comparisonView.length > 0 && layouts.comparisonViewLayout.length > 0 && loadingComparison) {
      setLoadingComparison(false);
    }
  }, [unitHistories, layouts, loadingComparison]);

  return (
    <div className="data-analytics-container">
      <FilterBar2
        selectedWeatherZephyr={selectedWeatherZephyr}
        getSelectedZephyrs={getSelectedZephyrs}
        selectedView={selectedView}
        setSelectedView={setSelectedView}
        networkViewUnitList={networkViewUnitList}
        comparisonViewZephyrs={comparisonViewZephyrs}
        handleFilterBarUpdates={handleFilterBarUpdates}
        // CSV
        getCsvData={getCSVData}
        unitHistories={unitHistories}
        getComparisonViewCSV={getComparisonViewCSV}
        userInfo={userInfo}
        layouts={layouts}
        setLayouts={setLayouts}
        defaultComparisonView={defaultComparisonView}
        defaultComparisonViewLayout={defaultComparisonViewLayout}
        setScatterChartsCounter={setScatterChartsCounter}
      />

      {selectedView === 'networkView' && layouts && layouts['networkView'] && !Array.isArray(unitHistories) && unitHistories.hasOwnProperty('historiesList') && unitHistories.historiesList.length > 0 && unitHistoriesLoading === false && selectedWeatherZephyr && isUnitHistoriesListUpdated() && !userInfo.isPublic ? (
        <ReactGridLayout
          // all
          zephyrs={zephyrs}
          unitHistories={unitHistories}
          selectedZephyr={selectedZephyrObj}
          selectedAveragingPeriod={selectedAveragingPeriod}
          selectedView={'networkView'}
          // summary
          selectedPollutant={selectedPollutant}
          // weather
          selectedWeatherZephyr={selectedWeatherZephyr}
          // pollutants
          selectedPollutants={selectedPollutants}
          option={chartOptions}
          notMerge
          style={{ height: '100%' }}
          localStorageKeysToSaveTo={[
            'networkView',
            'networkViewLayout',
          ]}
          thresholdsList={thresholdsList}
          setThresholdsList={setThresholdsList}
          userInfo={userInfo}
          layouts={layouts}
          setLayouts={setLayouts}
          setScatterChartsCounter={setScatterChartsCounter}
        />
      ) : null}

      {selectedView === 'deviceView' && layouts && layouts['deviceView'] && !Array.isArray(unitHistories) && unitHistories.hasOwnProperty('historiesList') && unitHistories.historiesList.length > 0 && unitHistoriesLoading === false && selectedWeatherZephyr && isUnitHistoriesListUpdated() ? (
        <ReactGridLayout
          // all
          zephyrs={zephyrs}
          unitHistories={unitHistories}
          selectedZephyr={selectedZephyrObj}
          selectedAveragingPeriod={selectedAveragingPeriod}
          selectedView={selectedView}
          // summary
          selectedPollutant={selectedPollutant}
          // weather
          selectedWeatherZephyr={selectedWeatherZephyr}
          // pollutants
          selectedPollutants={selectedPollutants}
          option={chartOptions}
          notMerge
          style={{ height: '100%' }}
          localStorageKeysToSaveTo={['deviceView', 'deviceViewLayout']}
          thresholdsList={thresholdsList}
          setThresholdsList={setThresholdsList}
          userInfo={userInfo}
          layouts={layouts}
          setLayouts={setLayouts}
          setScatterChartsCounter={setScatterChartsCounter}
        />
      ) : null}

      {selectedView === 'comparisonView' && layouts && layouts['comparisonView'] && selectedPollutants && !Array.isArray(unitHistories) && unitHistories.hasOwnProperty('historiesList') && unitHistories.historiesList.length > 0 && unitHistoriesLoading === false && !loadingComparison && selectedWeatherZephyr && isUnitHistoriesListUpdated() && !userInfo.isPublic ? (
        <ReactGridLayout
          // all
          zephyrs={zephyrs}
          unitHistories={unitHistories}
          selectedZephyr={selectedZephyrObj}
          selectedAveragingPeriod={selectedAveragingPeriod}
          selectedView={selectedView}
          // summary
          selectedPollutant={selectedPollutant}
          // weather
          selectedWeatherZephyr={selectedWeatherZephyr}
          // pollutants
          selectedPollutants={selectedPollutants}
          option={chartOptions}
          notMerge
          style={{ height: '100%' }}
          localStorageKeysToSaveTo={['comparisonView', 'comparisonViewLayout']}
          thresholdsList={thresholdsList}
          setThresholdsList={setThresholdsList}
          comparisonViewZephyrs={comparisonViewZephyrs}
          userInfo={userInfo}
          layouts={layouts}
          setLayouts={setLayouts}
          setScatterChartsCounter={setScatterChartsCounter}
        />
      ) : null}

      <Modal
        on={unitHistoriesLoading}
        modalComponent={
          <AnalyticsProgressBar />
        }
      />

      {!userInfo.isPublic && <FeedbackFormModal />}
    </div>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  isZephyrsLoading: state.getZephyrs.loading,
  zephyrs: state.getZephyrs.zephyrs,
  unitHistories: state.unitHistoriesOptions.unitHistories,
  unitHistoriesLoading: state.unitHistoriesOptions.isUnitHistoriesLoading,
  thresholdLimits: state.unitHistoriesOptions.thresholdLimits,
  zephyrCoveragesList: state.unitHistoriesOptions.zephyrCoveragesList,
  loading: state.loading,
  bearerToken: state.auth.bearerToken,
  asideOn: state.aside.on,
  requests: state.unitHistoriesOptions.requests,
  nullableUnits: state.unitHistoriesOptions.nullableUnits,
  toProceed: state.unitHistoriesOptions.toProceed,
  viewLoaded: state.unitHistoriesOptions.viewLoaded,
  isCartridgeDataLoading: state.unitHistoriesOptions.isCartridgeDataLoading,
  userInfo: state.auth.userInfo,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      closeAside,
      openAside,
      setUnitHistoriesList,
      setThresholdLimits,
      setIsViewLoaded,
      setIsCartridgeDataLoading,
      setZephyrCoveragesList,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DataAnalyticsContainer);
