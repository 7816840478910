// Package Imports
import React, { useState, useEffect, useContext } from 'react';
import { connect, useSelector } from 'react-redux';
import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import utilsRequest from '../../utils/request'
// Component Imports
import Dropdown from '../shared_components/Dropdown';

// Util Imports
import {
  speciesExtentInputter,
  speciesExtentProvider,
  speciesExtentUpdater,
} from '../../utils/functions/speciesExtentFinder';
import { airAlertsConfiguratorPackager } from '../../utils/functions/airAlertsInterface';
import { getAuth } from '../../utils/functions/getAuth';

// Content Imports
import {
  alertPeriodSelections,
  alertRepeatSelections,
  alertThresholdSelections,
  gaseousSlots,
  unitSelections,
  unitStatus,
} from '../../utils/consts';


// Type Imports
import {
  AirAlerts,
  AlertErrors,
  AlertMappedSpeciesExtent,
  BatteryAlert,
  AlertObject,
  AlertPeriod,
  AlertRepeat,
  AlertThreshold,
  Auth,
  DisplayConfig,
  ReduxState,
  UnitSelection,
  Zephyr,
  ZephyrAlertUnit,
  SpeciesDataIdentifiers,
  APIAirAlertPost,
  APIAirAlertTarget,
  SpeciesAlert,
  SpeciesData,
  SlotsInfo,
} from '../../utils/interface';
import { setZephyrs } from '../../actions/zephyrs';
import Spinner from '../shared_components/Spinner';
import ZephyrSelectionDropdown from '../shared_components/ZephyrSelectionDropdown';

// Type Safety
interface AirAlertsOverviewProps {
  airAlerts: AirAlerts | null;
  auth: Auth;
  endEdit: Function;
  displayConfig: DisplayConfig;
  selectedAlert: AlertObject | null;
  updateAlert: Function;
  addTarget: Function;
  removeAlertTarget: Function;
  zephyrs: Zephyr[];
  bearerToken: string | null;
}

interface InitState {
  alert: AlertObject;
  alertsGasses: AlertMappedSpeciesExtent[];
  alertZephyrs: ZephyrAlertUnit[];
  errors: AlertErrors[];
  outputEmail: string;
  outputPhoneNumber: string;
  searchZephyrs: ZephyrAlertUnit[];
  zephyrSearch: string;
  zephyrSearchActive: boolean;
  unitSelection: UnitSelection;
  periodSelection: AlertPeriod;
}

// Component
const AirAlertsConfigurator = ({
  auth,
  endEdit,
  displayConfig,
  selectedAlert,
  updateAlert,
  addTarget,
  removeAlertTarget,
  zephyrs,
  bearerToken,
}: AirAlertsOverviewProps) => {
  const { t: translate } = useTranslation();
  const determineStartingUnitSelection = () => {
    const startingUnitSelection = unitSelections.unitSelectionOptions[0];
    if (selectedAlert) {
      const userCurZephyrs = zephyrs.filter(
        (z) => z.type === 0 && (!z.userEndTimeDate || new Date(z.userEndTimeDate) > new Date()),
      );
      const startingUnitSelection =
        selectedAlert.units.length === userCurZephyrs.length;
      if (startingUnitSelection) {
        return unitSelections.unitSelectionOptions[1];
      }
    }
    return startingUnitSelection;
  };

  // State
  const initState: InitState = {
    alert: {
      airAlerts: [],
      batteryAlert: {
        id: '',
        comparator: '',
        status: false,
        batteryCharge: 0,
        batteryVoltage: 0,
        externalVoltage: 0,
        znum: 0,
      },
      id: null,
      name: '',
      units: [],
      status: true,
      email: [],
      phoneNumbers: [],
    },
    alertsGasses: [],
    alertZephyrs: [],
    errors: [],
    outputEmail: '',
    outputPhoneNumber: '',
    searchZephyrs: [],
    zephyrSearch: '',
    zephyrSearchActive: false,
    unitSelection: determineStartingUnitSelection(),
    periodSelection: '',
  };

  const [alert, setAlert] = useState(initState.alert);
  const [alertGasses, setAlertGasses] = useState(initState.alertsGasses);
  const [batteryAlert, setBatteryAlert] = useState(
    initState.alert.batteryAlert,
  );
  const [alertZephyrs, setAlertZephyrs] = useState(initState.alertZephyrs);
  const [errors, setErrors] = useState(initState.errors);
  const [outputEmail, setOutputEmail] = useState(initState.outputEmail);
  const [outputPhoneNumber, setPhoneNumber] = useState(initState.outputPhoneNumber)
  const [searchZephyrs, setSearchZephyrs] = useState(initState.searchZephyrs);
  const [zephyrSearch, setZephyrSearch] = useState(initState.zephyrSearch);
  const [zephyrSearchActive, setZephyrSearchActive] = useState(
    initState.zephyrSearchActive,
  );
  const [unitSelection, setUnitSelection] = useState(initState.unitSelection);
  const [periodSelection, setTimePeriod] = useState(initState.periodSelection);
  const [isCartridgeDataLoading, setIsCartridgeDataLoading] = useState<boolean>(false);
  const phoneValidation = '^([+])([0-9]){9,12}';
  const emailValidation = '[a-z0-9]+(?:\.[a-z0-9]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?';
  
  const alertsTourOpen = useSelector((state: ReduxState) => state.alertsTourOpen);

  // Effects
  useEffect(() => {
    // Populate state with current state of the alert
    // Determine whether specific units or all units
    const userCurZephyrs = zephyrs.filter(
      (z) => z.type === 0 && (!z.userEndTimeDate || new Date(z.userEndTimeDate) > new Date()),
    );
    const enrichedUserAlertZephyrs = userCurZephyrs.map((z) => {
      const enrichedZephyr = { ...z, alertStatus: false };
      return enrichedZephyr;
    });
    // Exploit closure for species list
    let selectedAlertZephyrSet = enrichedUserAlertZephyrs;
    if (selectedAlert) {
      const enrichedSelectedUserAlertZephyrs = enrichedUserAlertZephyrs.map(
        (z) => {
          const enrichedSelectedZephyr = {
            ...z,
            alertStatus: selectedAlert.units.includes(z.zNumber),
          };
          return enrichedSelectedZephyr;
        },
      );
      const determinedSpecificUnit =
        selectedAlert.units.length === userCurZephyrs.length
          ? unitSelections.unitSelectionOptions[1]
          : unitSelections.unitSelectionOptions[0];
      selectedAlertZephyrSet = enrichedSelectedUserAlertZephyrs;
      setAlert(selectedAlert);
      setUnitSelection(determinedSpecificUnit);
      setAlertZephyrs(enrichedSelectedUserAlertZephyrs);
    } else {
      setAlertZephyrs(enrichedUserAlertZephyrs);
    }
    // Initialize set of species for gas table
    const airAlerts: SpeciesData[] = selectedAlert
      ? (selectedAlert.airAlerts as SpeciesData[])
      : [];
    const selectedZephyrs = selectedAlertZephyrSet.filter((z) => z.alertStatus);
    const mappedSpeciesExtent = speciesExtentProvider(
      airAlerts,
      displayConfig,
      selectedZephyrs,
      zephyrs
    );
    setAlertGasses(mappedSpeciesExtent);
  }, [isCartridgeDataLoading]);

  useEffect(() => {
    const updateCartridgeData = async () => {
      if (zephyrs) {
        for await (const z of zephyrs) {
          if (!z.slotsInfo) {
            const packagedSlotsInfo: SlotsInfo = {
              slotA: null,
              slotB: null,
            };
            if (!z.userEndTimeDate || new Date(z.userEndTimeDate) > new Date()) {
              for await (const gS of gaseousSlots) {
                const slotSerial = z[`${gS.label}_SN`];
                if (slotSerial) {
                  const slotInfo = await utilsRequest.getCartridge(slotSerial, bearerToken);
                  if (slotInfo) {
                    packagedSlotsInfo[gS.label] = slotInfo;
                  }
                }
              };
            }
            const copyZephyrsList = [...zephyrs];
            const newData = copyZephyrsList.find((zR) => zR.id_pod === z.id_pod);
            if (newData) newData.slotsInfo = packagedSlotsInfo;
            setZephyrs(copyZephyrsList);
          }
        }
      }
    }
    // IIFY
    (async () => {
      setIsCartridgeDataLoading(true);
      await updateCartridgeData();
      setIsCartridgeDataLoading(false);
    })();
  }, [])

  // Functions
  const addAlertGas = (dataIdentifier: SpeciesDataIdentifiers) => {
    const updatedAlertGasses = speciesExtentInputter(
      dataIdentifier,
      displayConfig,
      alertGasses,
    );
    setAlertGasses(updatedAlertGasses);
  };

  const addEmailAddress = () => {
    if (outputEmail) {
      if (!alert.email.includes(outputEmail)) {
        if (!outputEmail.match(emailValidation)) {
          const errors: AlertErrors[] = ['email'];
          setErrors(errors);
          return;
        }
        const updatedAlertEmailList = [...alert.email, outputEmail];
        const updatedAlert = { ...alert, email: updatedAlertEmailList };
        submitAddingTarget();
        setAlert(updatedAlert);
        setOutputEmail(initState.outputEmail);
        removeError('email');
      }
    }
  };

  const addPhoneNumbers = () => {
    if (outputPhoneNumber) {
      if (!alert.phoneNumbers.includes(outputPhoneNumber)) {
        if (!outputPhoneNumber.match(phoneValidation)) {
          const errors: AlertErrors[] = ['phonenumber'];
          setErrors(errors);
          return;
        }
        const updatedAlertEmailList = [...alert.phoneNumbers, outputPhoneNumber];
        const updatedAlert = { ...alert, phoneNumbers: updatedAlertEmailList };
        submitAddingTarget();
        setAlert(updatedAlert);
        setPhoneNumber(initState.outputPhoneNumber);
        removeError('phonenumber');
      }
    }
  }

  const changeAlertZephyrStatus = (zNumber: number) => {
    const updatedAlertZephyrs = alertZephyrs.map((z) => {
      const updatedAlertZephyr = z;
      if (z.zNumber === zNumber) {
        updatedAlertZephyr.alertStatus = !updatedAlertZephyr.alertStatus;
      }
      return updatedAlertZephyr;
    });
    const updatedSearchUnits = updatedAlertZephyrs.filter(
      (z) => !z.alertStatus,
    );
    if (zephyrSearchActive) {
      setSearchZephyrs(updatedSearchUnits);
    }
    if (updatedSearchUnits.length === 0) {
      setUnitSelection(unitSelections.unitSelectionOptions[1]);
    } else {
      setUnitSelection(unitSelections.unitSelectionOptions[0]);
    }
    const selectedAlertZephyrs = updatedAlertZephyrs.filter(
      (z) => z.alertStatus,
    );
    // console.log(displayConfig, alertGasses);
    const updatedAlertGasses = speciesExtentUpdater(
      displayConfig,
      alertGasses,
      selectedAlertZephyrs,
      zephyrs
    );
    if (selectedAlertZephyrs.length) {
      removeError('sourceSelect');
    }
    // console.log(updatedAlertGasses);
    setAlertGasses(updatedAlertGasses);
    setAlertZephyrs(updatedAlertZephyrs);
  };

  const checkCompleteGasAlert = (airAlertId: string, typeAdded: string) => {
    const alert = alertGasses.filter((ag) => ag.id === airAlertId)[0];
    alert[typeAdded] = true;
    const { alertLevel, threshold, status } = alert;
    const completedAlert = alertLevel && threshold && status;
    if (completedAlert) {
      removeError('dataSelect');
    }
  };

  const determineSearchableList = () => {
    if (zephyrSearchActive) {
      return searchZephyrs;
    }
    const searchableZephyrs = alertZephyrs.filter((z) => !z.alertStatus);
    return searchableZephyrs;
  };

  const submitAddingTarget = () => {
    if (selectedAlert) {
      const target: APIAirAlertTarget = {
        //ToDo: Add first and last names at some point
        targetId: 0,
        firstName: '',
        lastName: '',
        emailAddress: outputEmail ?? null,
        phoneNumber: outputPhoneNumber ?? null,
        organisation: auth.user,
        alertId: alert.id
      };
      addTarget(target);
    }
  }
  //};
  const handleAlertSubmission = () => {
    // add zephyrs and air alerts
    const auth2 = getAuth();
    const alertZephyrsList = alertZephyrs
      .filter((az) => az.alertStatus)
      .map((az) => az.zNumber);
    const alertGassesList: SpeciesData[] = [];
    alertGasses.forEach((ag) => {
      if (ag.status && ag.dataIdentifier && ag.threshold && ag.alertLevel) {
        const newAlertGas: SpeciesData = {
          Species: ag.dataIdentifier,
          Comparator: ag.threshold,
          Threshold: parseInt(ag.alertLevel),
          Duration: periodSelection ? parseInt(periodSelection) : selectedAlert!.airAlerts[0].Duration,
          username: auth2,
          userkey: auth2,
          Znumbers: alertZephyrsList,
        };
        alertGassesList.push(newAlertGas);
      }
    });
    // check for minimum alert requirements and update state if missed
    let hasUnitAlert: boolean = false;
    const updatedSpecificErrors: AlertErrors[] = [];
    if (!alertZephyrsList.length) {
      updatedSpecificErrors.push('sourceSelect');
    }

    if (!(alertGassesList.length || hasUnitAlert)) {
      updatedSpecificErrors.push('dataSelect');
    }
    if (alertGassesList.length > 0) {
      alertGassesList.forEach((ag) => {
        if (ag.Threshold == null || ag.Threshold < 0 || ag.Threshold > 10000) {
          updatedSpecificErrors.push('concentrationLimit');
        }
      });
    }
    if (!alert.name) {
      updatedSpecificErrors.push('name');
    }
    let hasCommunication = alert.phoneNumbers.length || alert.email.length || outputEmail || outputPhoneNumber;
    if (!hasCommunication) {
      updatedSpecificErrors.push('communication');
      if (!alert.phoneNumbers.length && !outputPhoneNumber) {
        updatedSpecificErrors.push('phonenumber');
      }
      if (!alert.email.length && !outputEmail) {
        updatedSpecificErrors.push('email');
      }
    }

    if (!updatedSpecificErrors.length) {
      // TODO: get actual id from making request to backend on alert submission
      // Remove uuid and import
      let alertId = alert.id ? alert.id : 0;
      if (batteryAlert.batteryCharge !== 0) {
        alertId = 2;
      } else if (alertGassesList.length > 0) {
        alertId = 4;
      }
      const emailList = alert.email.length ? alert.email : [outputEmail];
      const phoneNumbersList = alert.phoneNumbers.length ? alert.phoneNumbers : [outputPhoneNumber];
      const updatedAlert = {
        ...alert,
        airAlerts: alertGassesList,
        alertZephyrsList,
        email: emailList,
        phoneNumbers: phoneNumbersList,
        id: alert.id,
        units: alertZephyrsList,
      };
      const packagedAirAlertsRequest = airAlertsConfiguratorPackager(
        auth,
        updatedAlert,
      );
      const informationToPost: APIAirAlertPost = {
        Alerts: packagedAirAlertsRequest,
      };
      updateAlert(informationToPost);
      endEdit();
    } else {
      setErrors(updatedSpecificErrors);
    }
  };

  const handleGasAlertLevel = (airAlertId: string, alertLevel: string) => {
    const updatedAlertGasses = alertGasses.map((ag) => {
      const updatedAlertGas = { ...ag };
      if (ag.id === airAlertId) {
        updatedAlertGas.alertLevel = alertLevel;
      }
      return updatedAlertGas;
    });
    const type = 'alertLevel';
    checkCompleteGasAlert(airAlertId, type);
    setAlertGasses(updatedAlertGasses);
  };

  const handleTimePeriod = (
    idwedontcareabout: string,
    alertPeriod: AlertPeriod,
  ) => {
    setTimePeriod(alertPeriod);
  };

  const handleGasSelection = (
    speciesDataIdentifier: SpeciesDataIdentifiers,
  ) => {
    const updatedAlertGasses = alertGasses.map((ag) => {
      const updatedAlertGas = { ...ag };
      if (ag.dataIdentifier === speciesDataIdentifier) {
        updatedAlertGas.status = !updatedAlertGas.status;
      }
      return updatedAlertGas;
    });
    const affectedAlerts = alertGasses.filter((ag) => ag.dataIdentifier);
    const type = 'status';
    affectedAlerts.forEach((aa) => checkCompleteGasAlert(aa.id, type));
    setAlertGasses(updatedAlertGasses);
  };

  const handleGasThreshold = (
    airAlertId: string,
    alertThreshold: AlertThreshold,
  ) => {
    const updatedAlertGasses = alertGasses.map((ag) => {
      const updatedAlertGas = { ...ag };
      if (ag.id === airAlertId) {
        updatedAlertGas.threshold = alertThreshold;
      }
      return updatedAlertGas;
    });
    const type = 'threshold';
    checkCompleteGasAlert(airAlertId, type);
    setAlertGasses(updatedAlertGasses);
  };

  const handleSearchToggle = () => {
    const searchableZephyrs = alertZephyrs.filter((z) => !z.alertStatus);
    if (zephyrSearchActive) {
      setZephyrSearch('');
      setSearchZephyrs(initState.searchZephyrs);
    } else {
      setSearchZephyrs(searchableZephyrs);
    }
    setZephyrSearchActive(!zephyrSearchActive);
  };

  const handleUnitSearch = (search: string) => {
    const userNonAlertZephyrs = alertZephyrs.filter((z) => !z.alertStatus);
    if (search === '') {
      setZephyrSearchActive(false);
      setSearchZephyrs([]);
    } else {
      const newFilteredZephyrs = userNonAlertZephyrs
        .slice()
        .filter(
          (z) =>
            z.name.toLowerCase().indexOf(search.toLowerCase()) > -1 ||
            z.zNumber.toString().indexOf(search) > -1,
        );
      setZephyrSearchActive(true);
      setSearchZephyrs(newFilteredZephyrs);
    }
    setZephyrSearch(search);
  };

  const handleUnitSelectionChange = (unitSelectionOption: UnitSelection) => {
    if (unitSelectionOption.type === 'specific') {
      const clearAlertStatus = alertZephyrs.map((z) => {
        const updatedZephyr = { ...z, alertStatus: false };
        return updatedZephyr;
      });
      setAlertZephyrs(clearAlertStatus)
    }
    if (unitSelectionOption.type === 'non-specific') {
      const updatedAlertZephyrs = alertZephyrs.map((z) => {
        const updatedAlertZephyr = { ...z, alertStatus: true };
        return updatedAlertZephyr;
      });
      const updatedAlertGasses = speciesExtentUpdater(
        displayConfig,
        alertGasses,
        updatedAlertZephyrs,
        zephyrs
      );
      removeError('sourceSelect');
      setZephyrSearchActive(false);
      setAlertZephyrs(updatedAlertZephyrs);
      setAlertGasses(updatedAlertGasses);
    }
    setUnitSelection(unitSelectionOption);
  };

  const removeAlertGas = (airAlertId: string) => {
    const updatedAlertGasses = alertGasses.filter((ag) => ag.id !== airAlertId);
    setAlertGasses(updatedAlertGasses);
  };

  const getTarget = (target: string): boolean => {
    return target.includes('@') ? true : false;
  };

  const targetToRemove = (source: string, target: string): void => {
    const isEmail = getTarget(target);
    const updateAlertData = isEmail ?       // if isEmail is true, the target is an email, otherwise it's a phone number
      alert.email.filter((e: string) => e !== target) :
      alert.phoneNumbers.filter((p: string) => p !== target);
    const updatedAlert = isEmail ? { ...alert, email: updateAlertData } : { ...alert, phoneNumbers: updateAlertData };
    if (source === 'email') { removeTarget(target, null) }
    if (source === 'phone') { removeTarget(null, target) }
    setAlert(updatedAlert);
  };

  const removeTarget = (email: string | null, phoneNumber: string | null) => {
    if (email || phoneNumber) {
      const target: APIAirAlertTarget = {
        //ToDo: Add first and last names at some point
        targetId: 0,
        firstName: '',
        lastName: '',
        emailAddress: email,
        phoneNumber: phoneNumber,
        organisation: auth.user,
        alertId: alert.id
      };
      removeAlertTarget(target);
    }
  };


  const removeError = (error: string) => {
    const updatedErrors = errors.filter((e) => e !== error);
    setErrors(updatedErrors);
  };

  const asteriskOutPhoneDigits = (address: string) => {
    const firstThreeDigits = address.slice(0, 3);
    const phoneNumSplit = address.split('').slice(firstThreeDigits.length, address.length - 3); // create an array of digits and removing the last 3
    const lastThreeDigits = address.slice(-3); // getting last three digits
    const digitsArr = phoneNumSplit.map((digit: string) => { digit = '*'; return digit; }); // replace each digit with asterix
    const hashedNumber = firstThreeDigits.concat('', digitsArr.join('').concat('', lastThreeDigits)); // convert the array of digits to string and concatenate it with the last three digits
    return hashedNumber;
  };

  // Component
  return !isCartridgeDataLoading ? (
    <div className="air-alerts-configurator-container">
      <div className="air-alerts-configurator-header">
        <h4>
          {alert.name
            ? alert.name
            : 'New Alert (Will only work if the 5-per user limit is not exceeded)'}
        </h4>
        {errors.length ? (
          <div className="air-alerts-error-header">
            {translate('AACValidationFailure')}
          </div>
        ) : (
          <></>
        )}
      </div>
      <div className="air-alerts-configurator">
        <div className="air-alert-configurator-main">
          <div
            className="air-alert-configurator-source"
            data-alerts-tour="step-3"
          >
            <h4>{translate('AACDataSourceHeader')}</h4>
            <div className="air-alert-configurator-unit-selection">
              {unitSelections.unitSelectionOptions.map((option) => (
                <label
                  className="radio"
                  htmlFor={`radio-${option.type}`}
                  key={option.type}
                >
                  {option.HTMLLabel}
                  <input
                    type="radio"
                    id={`radio-${option.type}`}
                    name="radio"
                    checked={option === unitSelection}
                    value={option.type}
                    onChange={() => {
                      handleUnitSelectionChange(option);
                    }}
                    disabled={
                      option.type === 'specific' &&
                      alertZephyrs.filter((z) => !z.alertStatus).length === 0
                    }
                    style={{
                      cursor:
                        isCartridgeDataLoading && option.type === 'non-specific'
                          ? 'not-allowed'
                          : 'pointer',
                    }}
                  />
                  <span className="checkmark" />
                </label>
              ))}
            </div>
            <div>
              {unitSelection === unitSelections.unitSelectionOptions[0] ? (
                <div className="air-alert-configurator-unit-search">
                  <h5>{translate('AACDataSourceSelection')}</h5>
                  {/* <ZephyrSelectionDropdown
                    zephyrSearch={zephyrSearch}
                    zephyrSearchActive={zephyrSearchActive}
                    handleUnitSearch={handleUnitSearch}
                    handleSearchToggle={handleSearchToggle}
                    determineSearchableList={determineSearchableList}
                    changeAlertZephyrStatus={changeAlertZephyrStatus} /> */}
                  <div className="air-alert-configuration-search">
                    <div className="air-alert-configuration-search-box">
                      <input
                        value={zephyrSearch}
                        onChange={(e) => handleUnitSearch(e.target.value)}
                        placeholder={
                          zephyrSearchActive
                            ? 'Choose Zephyrs'
                            : 'Search Zephyrs'
                        }
                      />
                      <button
                        className={zephyrSearchActive ? 'active' : ''}
                        onClick={() => handleSearchToggle()}
                      />
                    </div>
                    {zephyrSearchActive || alertsTourOpen ? (
                      <ul
                        className={`air-alert-configurator-unit-list add-units ${
                          determineSearchableList().length === 0 ? 'empty' : ''
                        }`}
                      >
                        {determineSearchableList().map((unit: any) => (
                          <li key={unit.name}>
                            <div>{unit.name}</div>
                            <button
                              onClick={() =>
                                changeAlertZephyrStatus(unit.zNumber)
                              }
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="10"
                                viewBox="0 0 24 24"
                                fill="#FFF"
                              >
                                <path d="M24 10h-10v-10h-4v10h-10v4h10v10h4v-10h10z" />
                              </svg>
                            </button>
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              ) : (
                <></>
              )}
              <ul className="air-alert-configurator-unit-list">
                {alertZephyrs
                  .filter((z) => z.alertStatus)
                  .map((z) => (
                    <li key={z.zNumber}>
                      <div>{z.name}</div>
                      <button
                        onClick={() => changeAlertZephyrStatus(z.zNumber)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="10"
                          height="10"
                          viewBox="0 0 24 24"
                          fill="#fff"
                        >
                          <path d="M23 20.168l-8.185-8.187 8.185-8.174-2.832-2.807-8.182 8.179-8.176-8.179-2.81 2.81 8.186 8.196-8.186 8.184 2.81 2.81 8.203-8.192 8.18 8.192z" />
                        </svg>
                      </button>
                    </li>
                  ))}
              </ul>
            </div>
            {errors.includes('sourceSelect') ? (
              <div className="air-alerts-error-item no-padding">
                {translate('AACValidationFailureSources')}
              </div>
            ) : (
              <></>
            )}
          </div>
          {alertZephyrs.filter((z) => z.alertStatus).length ? (
            <>
              <div className="air-alerts-configurator-data">
                <h4>{translate('AACAlertDataHeader')}</h4>
                <table
                  className="air-alerts-configurator-data-table"
                  data-alerts-tour="step-4"
                >
                  <tbody>
                    <tr>
                      <th className="air-alerts-configurator-gas">
                        {translate('AACGasColumn')}
                      </th>
                      <th className="air-alerts-configurator-threshold">
                        {translate('AACThresholdColumn')}
                        {errors.includes('concentrationLimit') ? (
                          <div className="air-alerts-error-item no-padding">
                            {'Please enter a value between 0 and 10,000'}
                          </div>
                        ) : (
                          <></>
                        )}
                      </th>
                      <th className="air-alerts-configurator-level">
                        {translate('AACAlertColumn')}
                      </th>
                      <th className="air-alerts-configurator-add" />
                    </tr>
                    {alertGasses.map((mSE, idx, arr) => (
                      <tr
                        key={mSE.id}
                        className={
                          mSE.status ? 'active-data-row' : 'inactive-data-row'
                        }
                      >
                        <td className="select-gas-column">
                          {idx === 0 ||
                          arr[idx - 1].dataIdentifier !== mSE.dataIdentifier ? (
                            <div
                              onClick={() =>
                                handleGasSelection(mSE.dataIdentifier)
                              }
                            >
                              <label
                                htmlFor={mSE.dataIdentifier}
                                className="checkbox"
                              >
                                {parse(mSE.HTMLLabel)}
                                <input
                                  type="checkbox"
                                  checked={mSE.status}
                                  value={mSE.dataIdentifier}
                                  onChange={() => null}
                                />
                                <span className="checkmark" />
                              </label>
                            </div>
                          ) : (
                            <></>
                          )}
                        </td>
                        <td>
                          <div className="air-alerts-configurator-data-table-cell">
                            <div className="add-space-right">is</div>
                            <Dropdown
                              selectedAlert={selectedAlert}
                              additionalClass="custom-dropdown-threshold-container"
                              currentSelectionValue={mSE.threshold}
                              handleSelection={handleGasThreshold}
                              id={mSE.id}
                              placeholderSelectionLabel="Select"
                              selections={alertThresholdSelections.map(
                                (th) => ({
                                  value: th.rawThreshold,
                                  label: th.formattedThreshold,
                                }),
                              )}
                            />
                            <div className="add-space-left">
                              {alertThresholdSelections.filter(
                                (th) => th.rawThreshold === mSE.threshold,
                              ).length
                                ? alertThresholdSelections.filter(
                                    (th) => th.rawThreshold === mSE.threshold,
                                  )[0].postVerb
                                : 'than'}
                            </div>
                          </div>
                        </td>
                        <td className="alert-level-column">
                          <div className="alert-level-cell">
                            <input
                              className={`air-alerts-configurator-level-input ${
                                mSE.status ? 'active-input' : 'inactive-input'
                              }`}
                              value={mSE.alertLevel}
                              type="number"
                              placeholder="0"
                              onChange={(e) =>
                                handleGasAlertLevel(mSE.id, e.target.value)
                              }
                            />
                            <div>{mSE.HTMLShortUnitLabel}</div>
                          </div>
                        </td>
                        <td className="alert-species-add">
                          <div>
                            {false ? (
                              idx === 0 ||
                              arr[idx - 1].dataIdentifier !==
                                mSE.dataIdentifier ? (
                                <button
                                  className="add-species-button"
                                  onClick={() =>
                                    addAlertGas(mSE.dataIdentifier)
                                  }
                                />
                              ) : (
                                <button
                                  className="remove-species-button"
                                  onClick={() => removeAlertGas(mSE.id)}
                                />
                              )
                            ) : (
                              <></>
                            )}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div data-alerts-tour="step-5" style={{ width: 'fit-content' }}>
                  <h5 style={{ display: 'inline-block' }}>Time period: </h5>
                  <Dropdown
                    alert={alert}
                    selectedAlert={selectedAlert}
                    currentSelectionValue={periodSelection}
                    handleSelection={handleTimePeriod}
                    id={'perioddropdown'}
                    placeholderSelectionLabel="Select"
                    selections={alertPeriodSelections.map((p) => ({
                      value: p.rawPeriod,
                      label: p.formattedPeriod,
                    }))}
                  />
                </div>
                {errors.includes('dataSelect') &&
                !errors.includes('sourceSelect') ? (
                  <div className="air-alerts-error-item">
                    {translate('AACValidationFailureData')}
                  </div>
                ) : (
                  <></>
                )}
              </div>
              <div className="air-alerts-configurator-output">
                <h4>{translate('AACOutputHeader')}</h4>
                <div className="air-alerts-configurator-output-name" data-alerts-tour="step-6">
                  <h5>{translate('AACOutputName')}</h5>
                  <input
                    placeholder="Add a name for this alert"
                    value={alert.name ?? ''}
                    onChange={(e) => {
                      const updatedAlert = { ...alert, name: e.target.value };
                      removeError('name');
                      setAlert(updatedAlert);
                    }}
                  />
                  {errors.includes('name') &&
                  !errors.includes('sourceSelect') ? (
                    <div className="air-alerts-error-item">
                      {translate('AACValidationFailureName')}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
                
                <div data-alerts-tour="step-7">
                <div className="air-alerts-configurator-output-field">
                  <h5>{translate('AACOuputEmail')}</h5>
                  <div className="air-alert-configuration-search-box air-alerts-configurator-output-email">
                    <input
                      placeholder="Add email addresses for this alert"
                      value={outputEmail}
                      onChange={(e) => setOutputEmail(e.target.value)}
                      onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                          addEmailAddress();
                        }
                      }}
                    />
                    <button onClick={() => addEmailAddress()} />
                  </div>
                  <ul className="air-alert-configurator-unit-list">
                    {alert.email.map((address) => (
                      <li key={address}>
                        <div>{address}</div>
                        <button
                          onClick={() => targetToRemove('email', address)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="10"
                            height="10"
                            viewBox="0 0 24 24"
                            fill="#fff"
                          >
                            <path d="M23 20.168l-8.185-8.187 8.185-8.174-2.832-2.807-8.182 8.179-8.176-8.179-2.81 2.81 8.186 8.196-8.186 8.184 2.81 2.81 8.203-8.192 8.18 8.192z" />
                          </svg>
                        </button>
                      </li>
                    ))}
                  </ul>
                  {errors.includes('email') &&
                  !errors.includes('sourceSelect') ? (
                    <div className="air-alerts-error-item no-padding">
                      {translate('AACValidationFailureEmail')}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="air-alerts-configurator-output-field">
                  <h5>{translate('AACOutputPhoneNumber')}</h5>
                  <div className="air-alert-configuration-search-box air-alerts-configurator-output-email">
                    <input
                      placeholder="Add phone numbers for this alert"
                      value={outputPhoneNumber}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                      onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                          addPhoneNumbers();
                        }
                      }}
                    />
                    <button onClick={() => addPhoneNumbers()} />
                  </div>
                  <ul className="air-alert-configurator-unit-list">
                    {alert.phoneNumbers.map((address) => (
                      <li key={address}>
                        <div>{asteriskOutPhoneDigits(address)}</div>
                        <button
                          onClick={() => targetToRemove('phone', address)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="10"
                            height="10"
                            viewBox="0 0 24 24"
                            fill="#fff"
                          >
                            <path d="M23 20.168l-8.185-8.187 8.185-8.174-2.832-2.807-8.182 8.179-8.176-8.179-2.81 2.81 8.186 8.196-8.186 8.184 2.81 2.81 8.203-8.192 8.18 8.192z" />
                          </svg>
                        </button>
                      </li>
                    ))}
                  </ul>
                  {errors.includes('phonenumber') &&
                  !errors.includes('sourceSelect') ? (
                    <div className="air-alerts-error-item no-padding">
                      {translate('AACValidationFailurePhoneNumber')}
                    </div>
                  ) : (
                    <></>
                  )}
                  </div>
                  </div>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className="air-alerts-configurator-footer">
        <button
          className="air-alerts-secondary-button"
          onClick={() => endEdit()}
        >
          Cancel
        </button>
        <button onClick={() => handleAlertSubmission()} data-alerts-tour="step-8">
          {selectedAlert ? 'Update alert' : 'Create alert'}
        </button>
      </div>
    </div>
  ) : (
    <div className="alerts-configurator-spinner-holder">
      <Spinner
        additionalClass="header-spinner dark-spinner see-through"
        on={isCartridgeDataLoading}
      />
    </div>
  );
};

// Redux
const mapStateToProps = (state: ReduxState) => ({
  auth: state.auth,
  zephyrs: state.getZephyrs.zephyrs,
  bearerToken: state.auth.bearerToken,
});

export default connect(mapStateToProps, {})(AirAlertsConfigurator);
