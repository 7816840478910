const generateCSVName = (
  unitHistories: any,
  name?: string,
  selectedWeatherZephyr?: any,
  selectedView?: string
) => {
  const { historiesList } = unitHistories;
  const { id } = selectedWeatherZephyr;
  const { selectedAveragingPeriod: { labelHTML } } = Object.values(historiesList[0])[0] as any;
  const formattedAveraginOption = `${labelHTML.split(' ')[0]}${labelHTML.toLowerCase().includes('min') ? 'min' : 'hr'}`;
  const {
    selectedDate: { start, end },
  } = historiesList[0][Object.keys(historiesList[0])[0]];
  return `${selectedView === 'deviceView' ? `device_${id}` : selectedView === 'networkView' ? 'network' : 'comparison'}_${start}_${end}_${formattedAveraginOption}.${selectedView !== 'comparisonView' ? 'csv' : 'zip'}`
};

const splitByFirstNumber = (pollutant: string) => {
  let result = pollutant.match(/\d/);

  // If a number is found, split the string at that index
  if (result) {
    let index = result.index;
    let part1 = pollutant.slice(0, index);
    let part2 = pollutant.slice(index);
    return [part1, part2];
  }
  return pollutant;
};

export const defaultDeviceView = [
  // map
  {
    w: 12,
    h: 8,
    x: 8,
    y: 0,
    i: 'mapWidget',
    moved: false,
    static: false,
    maxH: 18,
    minH: 3,
    minW: 4
  },
  // gases
  {
    w: 8,
    h: 10,
    x: 0,
    y: 8,
    i: 'gasWidget',
    moved: false,
    static: false,
    maxH: 18,
    minH: 4,
    minW: 4,
    counter: 1
  },
  // gas averages
  {
    w: 4,
    h: 4,
    x: 8,
    y: 8,
    i: 'gasAveragesWidget',
    moved: false,
    static: false,
    maxH: 4,
    minH: 4,
    minW: 4,
  },
  // source apportionment no2
  {
    w: 4,
    h: 6,
    x: 8,
    y: 12,
    i: 'saNOxWidget',
    moved: false,
    static: false,
    maxH: 6,
    minH: 6,
    minW: 4
  },

  // particulates
  {
    w: 8,
    h: 10,
    x: 0,
    y: 18,
    i: 'particulateWidget',
    moved: false,
    static: false,
    maxH: 18,
    minH: 4,
    minW: 4,
    counter: 1
  },
  // particulates averages
  {
    w: 4,
    h: 4,
    x: 8,
    y: 18,
    i: 'particulateAveragesWidget',
    moved: false,
    static: false,
    maxH: 4,
    minH: 4,
    minW: 4,
  },
  // source apportionment pm2.5
  {
    w: 4,
    h: 6,
    x: 8,
    y: 22,
    i: 'saPM25Widget',
    moved: false,
    static: false,
    maxH: 6,
    minH: 6,
    minW: 4,
  },
  // precipitation
  {
    w: 4,
    h: 8,
    x: 8,
    y: 28,
    i: 'precipitationWidget',
    moved: false,
    static: false,
    maxH: 18,
    minH: 4,
    minW: 4,
  },
  // thpChartPlotly
  {
    w: 4,
    h: 8,
    x: 0,
    y: 28,
    i: 'thpWidget',
    moved: false,
    static: false,
    maxH: 18,
    minH: 4,
    minW: 4,
    counter: 1
  },
  // windSpeedDirChartPlotly
  {
    w: 4,
    h: 8,
    x: 4,
    y: 28,
    i: 'wsdWidget',
    moved: false,
    static: false,
    maxH: 18,
    minH: 4,
    minW: 4,
    counter: 1
  },
];

export const defaultDeviceViewLayout = [
  {
    uid: 'gasAveragesWidget',
    name: 'gasAverages',
    i: 'gasAveragesWidget',
    threshold: false,
  },
  {
    uid: 'particulateAveragesWidget',
    name: 'pmAverages',
    i: 'particulateAveragesWidget',
    threshold: false,
  },

  {
    uid: 'precipitationWidget',
    name: 'precipitation',
    i: 'precipitationWidget',
    threshold: false,
  },
  {
    uid: 'mapWidget',
    name: 'map',
    i: 'mapWidget',
    threshold: false,
  },
  {
    uid: 'particulateWidget',
    name: 'particulate',
    i: 'particulateWidget',
    threshold: true,
  },
  {
    uid: 'thpWidget',
    name: 'thpChartPlotly',
    i: 'thpWidget',
    threshold: false,
  },
  {
    uid: 'wsdWidget',
    name: 'windSpeedDirChartPlotly',
    i: 'wsdWidget',
    threshold: false,
  },
  {
    uid: 'gasWidget',
    name: 'gases',
    i: 'gasWidget',
    threshold: true,
  },
  {
    uid: 'saNOxWidget',
    name: 'sourceApportionmentNO2',
    i: 'saNOxWidget',
    threshold: false,
  },
  {
    uid: 'saPM25Widget',
    name: 'sourceApportionmentPM25',
    i: 'saPM25Widget',
    threshold: false,
  },
];

export const defaultNetworkView = [
  { w: 8, h: 9, x: 0, y: 0, i: 'networkGasWidget', moved: false, static: false, maxH: 18, minH: 4, minW: 4, counter: 1 },
  { w: 8, h: 9, x: 0, y: 9, i: 'networkParticulateWidget', moved: false, static: false, maxH: 18, minH: 4, minW: 4, counter: 1 },
  { w: 12, h: 10, x: 0, y: 18, i: 'networkSummaryWidget', moved: false, static: false, maxH: 18, minH: 4, minW: 4 },
  { w: 4, h: 18, x: 8, y: 0, i: 'networkMapWidget', moved: false, static: false, maxH: 18, minH: 4, minW: 4 },
];

export const defaultNetworkViewLayout = [
  { uid: 'networkGasWidget', name: 'networkViewGases', i: 'networkGasWidget', threshold: true },
  { uid: 'networkParticulateWidget', name: 'networkViewParticulates', i: 'networkParticulateWidget', threshold: true },
  { uid: 'networkSummaryWidget', name: 'networkViewPollutantSummary', i: 'networkSummaryWidget', threshold: false },
  { uid: 'networkMapWidget', name: 'networkViewMap', i: 'networkMapWidget', threshold: false },
];

const defaultMobilePublicPortalItems = [
  {
    w: 12,
    h: 8,
    x: 0,
    y: 0,
    i: 'mapWidget', // map
    moved: false,
    static: false,
  },
  {
    w: 12,
    h: 8,
    x: 0,
    y: 8,
    i: 'gasWidget', // gases
    moved: false,
    static: false,
  },
  {
    w: 12,
    h: 8,
    x: 0,
    y: 16,
    i: 'particulateWidget', // particulates
    moved: false,
    static: false,
  },
]

const defaultMobilePublicPortalLayout = [
  {
    uid: 'gasWidget',
    name: 'gases',
    i: 'gasWidget',
    threshold: true,
  },
  {
    uid: 'particulateWidget',
    name: 'particulate',
    i: 'particulateWidget',
    threshold: true,
  },
  {
    uid: 'mapWidget',
    name: 'map',
    i: 'mapWidget',
    threshold: false,
  },
];

const defaultPublicPortalItems = [
  {
    w: 8,
    h: 8,
    x: 0,
    y: 0,
    i: 'gasWidget',
    moved: false,
    static: false,
  },
  {
    w: 8,
    h: 8,
    x: 0,
    y: 8,
    i: 'particulateWidget',
    moved: false,
    static: false,
  },
  {
    w: 4,
    h: 16,
    x: 8,
    y: 0,
    i: 'mapWidget',
    moved: false,
    static: false,
  },
];

const defaultPublicPortalLayout = [
  {
    uid: 'gasWidget',
    name: 'gases',
    i: 'gasWidget',
    threshold: true,
  },
  {
    uid: 'particulateWidget',
    name: 'particulate',
    i: 'particulateWidget',
    threshold: true,
  },
  {
    uid: 'mapWidget',
    name: 'map',
    i: 'mapWidget',
    threshold: false,
  },
];

const defaultLayoutGenerator = (isPublic: boolean, screenWidth?: number) => {

  if (isPublic) {
    // Return the mobile layout if screen width is 767px or below
    const isMobile = screenWidth ? screenWidth <= 767 : false;

    return isMobile ? {
      deviceView: defaultMobilePublicPortalItems,
      deviceViewLayout: defaultMobilePublicPortalLayout,
    } : {
      deviceView: defaultPublicPortalItems,
      deviceViewLayout: defaultPublicPortalLayout,
    };
  }

  // Default or desktop layout for wider screens for private users
  return {
    deviceView: defaultDeviceView,
    deviceViewLayout: defaultDeviceViewLayout,
    networkView: defaultNetworkView,
    networkViewLayout: defaultNetworkViewLayout,
  };
};

const updateScatterChartNumber = (setScatterChartsCounter: Function, selectedView: string, widgetName: string, increment: boolean) => {
  const widgetTypes = ['gasWidget', 'particulateWidget', 'thpWidget', 'wsdWidget', 'networkGasWidget', 'networkParticulateWidget'];

  if ((selectedView === 'deviceView' || selectedView === 'networkView') && widgetTypes.some(type => widgetName.includes(type))) {
    setScatterChartsCounter((sCC: {
      deviceView: { gasWidget: number; particulateWidget: number; thpWidget: number; wsdWidget: number; };
      networkView: { networkGasWidget: number; networkParticulateWidget: number; }
    }) => {
      const widget = Object.keys(sCC[selectedView]).find((w: string) => widgetName.includes(w)) as string;

      const updatedCounter = increment ? (sCC as any)[selectedView][widget] + 1 : (sCC as any)[selectedView][widget] - 1;

      return {
        ...sCC,
        [selectedView]: {
          ...sCC[selectedView],
          [widget]: updatedCounter
        }
      };
    });
  }
};

export { generateCSVName, splitByFirstNumber, defaultLayoutGenerator, updateScatterChartNumber };
