import * as React from 'react';
import styled, { keyframes } from 'styled-components';

type Props = {
  color: string;
  isActive: boolean;
  isALHDLoading?: boolean;
};

interface HSLArray extends Array<number> {
  0: number;
  1: number;
  2: number;
  length: 3;
}

const shimmer = keyframes`
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(50%);
  }
`;

const ShimmerOverlay = styled.rect`
  fill: url(#shimmerGradient);
  width: 30%;
  height: 26px;
  transform: translateX(-100%);
  animation: ${shimmer} 0.9s infinite linear;
  filter: blur(1px);
`;

const SVGBase = styled.svg<any>`
  position: relative;
  transition: all 0.3s ease;
  filter: drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.4));
  cursor: pointer;

  & .stroke {
    display: none;
  }

  &:hover,
  &.active {
    transform: scale(1.5);
  }

  &.active .stroke {
    display: revert;
  }

  ${(props) =>
    props.isALHDLoading &&
    `
    filter: blur(0.8px);
  `}
`;

function gammaCorrect(value: number): number {
  return value <= 0.04045 ? value / 12.92 : Math.pow((value + 0.055) / 1.055, 2.4);
}

function rgbaToHsl(rgba: string): HSLArray {
  const defaultHsl: HSLArray = [0, 0, 100];

  const match = rgba.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*[\d.]+)?\)$/);
  if (!match) return defaultHsl;

  // Gamma correction for perceived brightness
  const r = gammaCorrect(Number.parseInt(match[1], 10) / 255);
  const g = gammaCorrect(Number.parseInt(match[2], 10) / 255);
  const b = gammaCorrect(Number.parseInt(match[3], 10) / 255);

  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);
  let h = 0;
  let s = 0;
  const l = (max + min) / 2;

  if (max !== min) {
    const d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
    }
    h /= 6;
  }

  return [Math.round(h * 360), Math.round(s * 100), Math.round(l * 100)];
}

function whiteToGrey(rgba: string): string {
  const [h, s, l] = rgbaToHsl(rgba);

  // If it's white or near-white, return grey
  if (h === 0 && s === 0 && l >= 95) {
    return 'rgba(192, 202, 212, 1)';
  }

  return rgba;
}

export function Square({ color, isActive = false }: Props) {
  const convertedColor = whiteToGrey(color);
  const hslColor = rgbaToHsl(convertedColor);
  const [h, s, l] = hslColor;

  return (
    <SVGBase
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height={36}
      viewBox="0 0 36 36"
      fill="none"
      className={isActive ? 'active' : ''}
    >
      <rect
        x="1"
        y="1"
        width="34"
        height="34"
        stroke="#95ABDB"
        strokeWidth="4"
        className="stroke"
      />

      <rect
        x="3"
        y="3"
        width="30"
        height="30"
        fill={`hsl(${h}deg, ${s}%, ${l + 10}%)`}
        stroke="white"
        strokeWidth="3"
        opacity="0.9"
      />

      <rect
        x="6"
        y="6"
        width="24"
        height="24"
        stroke={`hsl(${h}deg, ${s}%, ${l}%)`}
        strokeWidth="4"
      />

      <rect
        opacity="0.8"
        x="14"
        y="14"
        width="8"
        height="8"
        fill={`hsl(${h}deg, ${s}%, ${l + 20}%)`}
      />
    </SVGBase>
  );
}

export function Circle({
  color = 'rgba(143, 207, 99, 0.6)',
  isActive = false,
  isALHDLoading,
}: Props) {
  const convertedColor = whiteToGrey(color);
  const hslColor = rgbaToHsl(convertedColor);
  const [h, s, l] = hslColor;

  return (
    <SVGBase
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={isActive ? 'active' : ''}
      isALHDLoading={isALHDLoading}
    >
      {/* Define the gradient for shimmer effect */}
      {isALHDLoading &&
        <defs>
          <linearGradient id="shimmerGradient" x1="0" y1="0" x2="1" y2="0">
            <stop offset="0%" stopColor="rgba(255, 255, 255, 0)" />
            <stop offset="50%" stopColor="rgba(255, 255, 255, 0.5)" />
            <stop offset="100%" stopColor="rgba(255, 255, 255, 0)" />
          </linearGradient>

          {/* Define a mask to clip the shimmer effect to the circular area */}
          <mask id="circleMask">
            <circle cx="18" cy="18" r="16" fill="white" />
          </mask>
        </defs>
      }

      <circle
        cx="18"
        cy="18"
        r="16"
        stroke="#95ABDB"
        strokeWidth="4"
        className="stroke"
      />
      <circle
        cx="18"
        cy="18"
        r="15"
        fill={`hsl(${h}deg, ${s}%, ${l + 10}%)`}
        stroke="white"
        strokeWidth="3"
        opacity="0.9"
      />
      <circle
        cx="18"
        cy="18"
        r="13"
        stroke={`hsl(${h}deg, ${s}%, ${l}%)`}
        strokeWidth="4"
      />
      <circle cx="18" cy="18" r="4" fill={`hsl(${h}deg, ${s}%, ${l + 20}%)`} />

      {isALHDLoading && (
        <ShimmerOverlay
          x="6"
          y="6"
          mask="url(#circleMask)"
        />
      )}
    </SVGBase>
  );
}

export function Triangle({
  color = 'rgba(143, 207, 99, 0.6)',
  isActive = false,
}: Props) {
  const [h, s, l] = rgbaToHsl(whiteToGrey(color));

  return (
    <SVGBase
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={isActive ? 'active' : ''}
    >
      <g transform="scale(0.645161)">
        <g filter="url(#filter0_d_5838_808)">
          <path
            d="M32.7889 8.10557L31 4.52786L29.2111 8.10557L5.21115 56.1056L3.76393 59H7H55H58.2361L56.7889 56.1056L32.7889 8.10557Z"
            stroke="#95ABDB"
            strokeWidth="4"
            className="stroke"
          />
          <path
            d="M31.8944 8.55279L31 6.76393L30.1056 8.55279L6.10557 56.5528L5.38197 58H7H55H56.618L55.8944 56.5528L31.8944 8.55279Z"
            fill={`hsl(${h}deg, ${s}%, ${l + 10}%)`}
            stroke="white"
            strokeWidth="3"
            opacity="0.9"
          />
          <path
            d="M10.2361 55L31 13.4721L51.7639 55H10.2361Z"
            stroke={`hsl(${h}deg, ${s}%, ${l}%)`}
            strokeWidth="4"
          />
          <path
            opacity="0.8"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M31 33L37 45H25L31 33Z"
            fill={`hsl(${h}deg, ${s}%, ${l + 20}%)`}
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_5838_808"
          x="0.527832"
          y="0.0556641"
          width="60.9443"
          height="60.9443"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_5838_808"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_5838_808"
            result="shape"
          />
        </filter>
      </defs>
    </SVGBase>
  );
}
