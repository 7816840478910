import React from 'react';
import AnalyticsFeedbackForm from '../view_components/AnalyticsFeedbackForm';
import FeedbackTag from '../view_components/FeedbackTag';
import Modal from './Modal';

export default function FeedbackFormModal() {
  const [showModal, setShowModal] = React.useState(false);
  const toggleModal = () => setShowModal(!showModal);

  return (
    <>
      <Modal
        on={showModal}
        modalComponent={
          <AnalyticsFeedbackForm setFeedbackModalOn={toggleModal} />
        }
      />
      <div className="feedback-container">
        <FeedbackTag on={true} setFeedbackOn={toggleModal} />
      </div>
    </>
  );
}
