import React, { Suspense, useMemo, useState } from 'react';
import { ChartProps } from '../../../utils/interface';
import { ChartTitleBar } from '../DataAnalyticsChartCtas';
import { draggingWidgetStyles } from '../../../utils/consts';

// make these dynamic in function using .includes to determine the chart type
const networkViewComponents: any = {
  networkViewMap: {
    component: React.lazy(() => import('../Charts/MiniMap')),
    // Data Analytics version 2.1- add chart widget. the below will be use as a preview with text...
    image: '/path/to/map/image.png',
    title: 'Network Map',
    description: 'Display the network map with relevant information.',
    html: '<div>Network Map</div>',
  },
  networkViewParticulates: {
    component: React.lazy(
      () => import('../Charts/MinMaxMeanStatisticsChartPlotly'),
    ),
  },
  networkViewGases: {
    component: React.lazy(
      () => import('../Charts/MinMaxMeanStatisticsChartPlotly'),
    ),
  },
  networkViewPollutantSummary: {
    component: React.lazy(() => import('../Charts/PollutantSummary')),
  },
};

// make these dynamic in function using .includes to determine the chart type
const deviceViewComponents: any = {
  precipitation: {
    component: React.lazy(() => import('../Charts/Precipitation')),
  },
  map: { component: React.lazy(() => import('../Charts/MiniMap')) },
  particulate: {
    component: React.lazy(() => import('../Charts/PollutantChartPlotly')),
  },
  gases: {
    component: React.lazy(() => import('../Charts/PollutantChartPlotly')),
  },
  thpChartPlotly: {
    component: React.lazy(() => import('../Charts/THPChartPlotly')),
  },
  windSpeedDirChartPlotly: {
    component: React.lazy(() => import('../Charts/WindSpeedDirChartPlotly')),
  },
  gasAverages: {
    component: React.lazy(() => import('../Charts/PollutantAverages')),
  },
  pmAverages: {
    component: React.lazy(() => import('../Charts/PollutantAverages')),
  },
  sourceApportionmentNO2: {
    component: React.lazy(
      () => import('../Charts/SourceApportionmentPieChart'),
    ),
  },
  sourceApportionmentPM25: {
    component: React.lazy(
      () => import('../Charts/SourceApportionmentPieChart'),
    ),
  },
};

export const lazyComponents = {
  ...networkViewComponents,
  ...deviceViewComponents,
};

const DynamicChart: React.FC<{
  type: string | undefined;
  props?: ChartProps;
  isDragging?: boolean;
  draggedItem?: string | null;
  onRemoveWidget?: () => void;
}> = React.memo(({ type = '', isDragging, onRemoveWidget, ...props }) => {
  const { selectedView, unitHistories, comparisonViewZephyrs, draggedItem, userInfo } = props as ChartProps;
  const [hoveredWidget, setHoveredWidget] = useState<string | null>(null);

  const ChartComponent = useMemo(() => {
    if (selectedView === 'comparisonView') {
      lazyComponents[type] = {
        component: React.lazy(() => import('../Charts/ComparisonPollutant')),
      };
    }
    const keys = !lazyComponents.hasOwnProperty(type) ? Object.keys(lazyComponents).find((el) => type.includes(el)) : null;
    return lazyComponents[keys ?? type]?.component;
  }, [unitHistories, comparisonViewZephyrs]);

  if (!ChartComponent) {
    // Handle unrecognized chart type
    return (
      <div className={`chart ${type} p-0`}>
        <ChartTitleBar title={`chart ${type}`} />
        <div className="chart center-text p-0">
          Unknown chart:
          {type}
        </div>
      </div>
    );
  }

  let title: string | undefined;
  if (type.includes('gasAverages')) {
    title = 'Gas Averages';
  } else if (type.includes('pmAverages')) {
    title = 'Particulate Averages';
  }
  const p = {
    ...props,
    title,
  };
  const chartsClassName = type.includes('thpChartPlotly') ? 'thpChartPlotly' : type.includes('windSpeedDirChartPlotly') ? 'windSpeedDirChartPlotly' : type.includes('precipitation') ? 'precipitation' : type.includes('sourceApportionmentNO2') ? 'sourceApportionmentNO2' : type.includes('sourceApportionmentPM25') ? 'sourceApportionmentPM25' : type;
  const curChartClassName = type.includes('networkViewPollutantSummary') && type !== 'networkViewPollutantSummary' ? 'networkViewPollutantSummary' : chartsClassName;

  return (
    <Suspense fallback={<div className="chart center-text">Loading...</div>}>
      <div className={`chart ${curChartClassName} ${selectedView === 'comparisonView' ? 'comparisonView' : undefined}`} onMouseEnter={() => { if (!userInfo.isPublic) setHoveredWidget(type) }} onMouseLeave={() => { if (!userInfo.isPublic) setHoveredWidget(null) }} style={{ ...isDragging && draggedItem === type && !userInfo.isPublic ? draggingWidgetStyles : {} }}>
        <ChartComponent {...p} type={type} isDragging={isDragging} draggedItem={draggedItem} isWidgetHovered={hoveredWidget === type} onRemoveWidget={onRemoveWidget} />
      </div>
    </Suspense>
  );
});

export default DynamicChart;
