import React, { useEffect, useState } from 'react';
import { Responsive, WidthProvider } from 'react-grid-layout';
import { v4 as uuidv4 } from 'uuid';

import '../../../../node_modules/react-grid-layout/css/styles.css';
import '../../../../node_modules/react-resizable/css/styles.css';
import {
  AveragingPeriod,
  GridItemInfo,
  LayoutItem,
  LayoutMapItem,
} from '../../../utils/interface';
import DynamicChart from './DynamicChart';
import { updateScatterChartNumber } from '../../../utils/functions/dataAnalytics';
import { Button } from '@mui/material';
import { Box, Modal } from '@mui/material';

export const iconsTypeMap = {
  'fa-chart-pie': 'exceedances',
  'fa-chart-line': 'weatherWind',
  'fa-chart-bar': 'weather',
  'fa-chart-area': 'weatherWindRose',
  'fa-font': 'precipitation',
  'fa-image': 'summary',
  'fa-list': 'map',
  'fa-table': 'table',
  'fa-tachometer-alt': 'pollutantAvg',
  'fa-layer-group': 'pollutants',
  'fa-layer-group2': 'pollutants2',
};

const isDevModeEnabled = true;

const ResponsiveReactGridLayout = WidthProvider(Responsive);

export default function AddRemoveLayout({ ...chartProps }) {
  const { localStorageKeysToSaveTo, selectedView, unitHistories, userInfo, layouts, setLayouts, setScatterChartsCounter } = chartProps;
  const [isDragging, setIsDragging] = useState(false); // Track dragging state
  const [items, setItems] = useState<any>([]);
  const [layoutMap, setLayoutMap] = useState<any>([]);
  const [config, setConfig] = useState({
    compactType: 'horizontal',
    breakpoints: { lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 },
    cols: { lg: 12, md: 12, sm: 12, xs: 12, xxs: 12 },
    // cols: { lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 },
    rowHeight: 30,
    isDraggable: !userInfo.isPublic && isDevModeEnabled,
    isResizable: !userInfo.isPublic && isDevModeEnabled,
  });
  const [draggedItem, setDraggedItem] = useState<string | null>(null);

  // need to update layout logic when old view is loaded
  const defaultLayout: any = { ...layouts };

  if (selectedView === 'comparisonView') {
    defaultLayout[localStorageKeysToSaveTo[0]] = layouts.comparisonView;
    defaultLayout[localStorageKeysToSaveTo[1]] = layouts.comparisonViewLayout;
  }

  useEffect(() => {
    const layoutSaved = defaultLayout[localStorageKeysToSaveTo[0]];
    const layoutMapSaved = defaultLayout[localStorageKeysToSaveTo[1]];

    setItems(layoutSaved);
    setLayoutMap(layoutMapSaved);
  }, [unitHistories, layouts]);

  const getGridItemInfo = (el: LayoutItem): LayoutMapItem | undefined => {
    let itemInfo = (layoutMap as GridItemInfo[])?.find(
      (item: LayoutMapItem) => el.i === item.i,
    );
    if (!itemInfo) {
      itemInfo = { i: el.i, uid: uuidv4(), type: '', name: 'not found' };
    }
    return itemInfo;
  };

  const createElement = (el: LayoutItem) => {
    const itemInfo = getGridItemInfo(el);
    const itemKey = itemInfo?.uid;

    return (
      <div
        key={itemKey}
        data-grid={el}
        className={`react-grid-item chart-${itemInfo?.name}-${itemKey}`}
      >
        <div style={{ height: 'inherit' }}>
          <DynamicChart
            type={itemInfo?.name}
            {...chartProps}
            isDragging={isDragging}
            draggedItem={draggedItem}
            onRemoveWidget={() => onRemoveItem(el.i)}
          />
        </div>
      </div>
    );
  };

  const onLayoutChange = (layouts: LayoutItem[]) => {
    let customizedLayouts = selectedView === 'comparisonView' ? [...JSON.parse(JSON.stringify(items))] : layouts;
    if (selectedView === 'comparisonView') {
      customizedLayouts.forEach(item => {
        const foundItem = layouts.find(i => item.i === i.i);
        if (foundItem) {
          const { w, h, x, y } = foundItem;
          item.w = w;
          item.h = h;
          item.x = x;
          item.y = y;
        }
      })
    }
    setLayouts((prevLayouts: any) => {
      return { ...prevLayouts, [selectedView]: customizedLayouts, [`${selectedView}Layout`]: !itemToRemove || selectedView === 'comparisonView' ? layoutMap : layoutMap.filter((l: { uid: string, name: string, i: string }) => l.i !== itemToRemove) };
    })
    if (itemToRemove) setItemToRemove(null);
    debouncedResizeEventToFixPlotlyScroll();
  };

  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [itemToRemove, setItemToRemove] = useState<string | null>(null);

  const handleOpenConfirmModal = (i: string) => {
    setItemToRemove(i);
    setOpenConfirmModal(true);
  };

  const handleCloseConfirmModal = () => {
    setOpenConfirmModal(false);
    // setItemToRemove(null);
  };

  const onRemoveItem = (i: string) => {
    handleOpenConfirmModal(i);
  };

  const handleConfirmRemove = () => {
    if (!itemToRemove) return;

    let newItems = [...JSON.parse(JSON.stringify(items))];
    if (selectedView !== 'comparisonView') newItems = newItems.filter((item: any) => item.i !== itemToRemove);
    else {
      // update flag for comparison view widgets
      for (const item of newItems) {
        if (item.i === itemToRemove) item.isRemoved = true;
      }
    }

    setItems(newItems);
    updateLayoutMap({
      action: 'remove',
      type: { UID: null, module: null, i: itemToRemove, gridItem_i: itemToRemove },
    });
    if(!userInfo.isPublic) updateScatterChartNumber(setScatterChartsCounter, selectedView, itemToRemove, false);
    handleCloseConfirmModal();
  };

  const updateLayoutMap = (obj: any) => {
    const { type } = obj;
    const { gridItem_i } = type;
    const { i } = type;

    let newLayoutMap = [...localStorageKeysToSaveTo[1]];
    const index = newLayoutMap.findIndex((item) => item.i === gridItem_i);

    switch (obj.action) {
      case 'remove':
        if (index === -1 || selectedView === 'comparisonView') return;
        newLayoutMap = newLayoutMap.filter((item) => item.i !== i);
        break;

      default:
        break;
    }

    if (obj.action) setLayoutMap(newLayoutMap);
  };

  const handleDragStart = (layout: any, oldItem: any) => {
    setIsDragging(true);

    // Detect the dragged item's ID or chart type
    const draggedItemId = oldItem.i;

    // Find the chart type using the items list and the unique ID
    const draggedWidget = layoutMap.find((item: any) => item.i === draggedItemId);
    const { name } = draggedWidget
    if (draggedItem !== name) setDraggedItem(name);
  };

  const handleDragStop = () => {
    setIsDragging(false);
  };

  return (
    <div>
      <Modal
        open={openConfirmModal}
        onClose={handleCloseConfirmModal}
        aria-labelledby="remove-widget-modal"
        aria-describedby="remove-widget-confirmation"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div
          className="modal-container header-contact-modal-container"
          style={{ padding: '24px' }}
        >
          <h4 style={{ marginBottom: '0px' }}>Confirm Removal</h4>
          <p style={{ marginTop: '0px' }}>
            Do you want to remove this widget from your dashboard view?
          </p>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              gap: '12px',
              paddingTop: '16px',
              borderTop: '1px solid #fafafa',
            }}
          >
            <Button
              variant="text"
              onClick={handleCloseConfirmModal}
              sx={{
                fontSize: 14,
                textTransform: 'none',
                fontWeight: 600,
                cursor: 'pointer'
              }}
            >
              Cancel
            </Button>

            <button
              type="button"
              className="button primary send-modal"
              onClick={handleConfirmRemove}
              style={{
                fontSize: 14,
                cursor: 'pointer'
              }}
            >
              Remove
            </button>
          </Box>
        </div>
      </Modal>

      <ResponsiveReactGridLayout
        className="layout"
        draggableHandle=".drag-handle"
        breakpoints={config.breakpoints}
        cols={config.cols}
        rowHeight={config.rowHeight}
        isDraggable={config.isDraggable}
        isResizable={config.isResizable}
        // compactType="horizontal"
        onLayoutChange={onLayoutChange}
        onDragStart={handleDragStart}
        onDragStop={handleDragStop}
      >
        {(selectedView === 'comparisonView' ? [...JSON.parse(JSON.stringify(items))].filter((item: any) => !item.isRemoved) : items).map((el: any) => createElement(el))}
      </ResponsiveReactGridLayout>
    </div>
  );
}

export const ChartNoDependencyDataMessage = ({ text = "No data available", proxyVZText = "Choose '1 Hour', '8 Hour' or '24 Hour' time average to see this data", HasMetStation, selectedAveragingPeriod }: { text?: string, proxyVZText?: string, HasMetStation?: number | null | undefined, selectedAveragingPeriod?: AveragingPeriod | undefined }) => {
  const updatedText = typeof HasMetStation === 'number' && HasMetStation === 0 && selectedAveragingPeriod && selectedAveragingPeriod.labelHTML !== '1 Hour' && selectedAveragingPeriod.labelHTML !== '8 Hour' && selectedAveragingPeriod.labelHTML !== '24 Hour' ? proxyVZText : text;
  return (
    <div className="d-grid align-content-center h-100">
      <p className="center-text">{updatedText}</p>
    </div>
  );
};

export const getSelectedZephyrObj = (
  unitHistories: any,
  selectedWeatherZephyr: any,
  zephyrs: any,
) => {
  const { historiesList } = unitHistories;
  const { id } = selectedWeatherZephyr;
  const unit = historiesList.find(
    (zephyr: any) => +(Object.keys(zephyr) as any)[0] === +id,
  );
  const unitKey = Number(Object.keys(unit)[0]);
  const { selectedAveragingPeriod, data } = unit[unitKey];

  const selectedZephyr = zephyrs.find(
    (zephyr: any) => zephyr.zNumber === unitKey,
  );
  return { selectedZephyr, selectedAveragingPeriod, data };
};

export const getZephyrType = (zType: number): string => {
  switch (zType) {
    case 0:
      return 'Zephyr';
    case 1:
      return 'AURNS';
    case 100:
      return 'Virtual Zephyr';

    default:
      return '';
  }
};

export function debounce<T extends (...args: any[]) => any>(
  func: T,
  wait: number,
): (...args: Parameters<T>) => void {
  let timeout: NodeJS.Timeout | null;

  return function (...args: Parameters<T>): void {
    const later = () => {
      timeout = null;
      func(...args);
    };

    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(later, wait);
  };
}

const debouncedResizeEventToFixPlotlyScroll = debounce(() => {
  window.dispatchEvent(new Event('resize'));
}, 250);
