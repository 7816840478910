// Module Type Imports
import Geometry from 'ol/geom/Geometry';
import { ModularFeatures } from '../reducers/modularFeatures';

// Interfaces

// Start Dynamic Charts
export interface ChartProps {
  [key: string]: any; // This allows any number of properties with any type
}
export interface GridItemInfo {
  i: string;
  uid: string;
  type?: string;
  name: string;
}
export interface LayoutItem {
  // A string corresponding to the component key
  i: string;
  id?: string;
  type?: string;

  // These are all in grid units, not pixels
  x: number;
  y: number;
  w: number;
  h: number;
  minW?: number;
  maxW?: number;
  minH?: number;
  maxH?: number;
  add?: boolean;
  // If true, equal to `isDraggable: false, isResizable: false`.
  static?: boolean;
  // If false, will not be draggable. Overrides `static`.
  isDraggable?: boolean;
  // If false, will not be resizable. Overrides `static`.
  isResizable?: boolean;
  // By default, a handle is only shown on the bottom-right (southeast) corner.
  // As of RGL >= 1.4.0, resizing on any corner works just fine!
  resizeHandles?: Array<'s' | 'w' | 'e' | 'n' | 'sw' | 'nw' | 'se' | 'ne'>;
  // If true and draggable, item will be moved only within grid.
  isBounded?: boolean;
  isRemoved?: boolean;
}

export interface LayoutMapItem {
  i: string;
  uid: string;
  type?: string;
  name: string;
}

// End Dynamic Charts

export interface ActiveTab {
  group: SpeciesGroupOptions;
  tab: SpeciesDataIdentifiers[];
  labels: SpeciesDataLabels[];
}

export interface Agreement {
  id: number;
  terms: string;
}

export interface AirAlerts {
  alerts: AlertObject[];
}

export interface SpeciesData {
  username: string;
  userkey: string;
  Znumbers: number[];
  Species: string;
  Threshold: number;
  Comparator: string;
  Duration: number; //This duration is in minutes
}

export interface BatteryAlert {
  id: string;
  znum: number;
  batteryVoltage: number;
  externalVoltage: number;
  batteryCharge: number;
  status: boolean;
  comparator: string;
}

export interface SpeciesAlert {
  alertLevel: number | null;
  period: AlertPeriod;
  repeat: AlertRepeat;
  species: SpeciesDataIdentifiers;
  threshold: AlertThreshold;
}

export interface AlertObject {
  airAlerts: SpeciesData[];
  batteryAlert: BatteryAlert;
  email: string[];
  phoneNumbers: string[];
  // TBD
  id: number | null;
  name: string | null;
  status: boolean;
  units: number[];
}

export interface AlertMappedSpeciesExtent {
  alertLevel: string;
  dataIdentifier: SpeciesDataIdentifiers;
  HTMLLabel: string;
  HTMLShortUnitLabel: string;
  HTMLUnitLabel: string;
  id: string;
  period: AlertPeriod;
  repeat: AlertRepeat;
  speciesExtentIdentifier: SpeciesExtentIdentifiers;
  status: boolean;
  threshold: AlertThreshold;
  [key: string]: string | boolean;
}

export interface AlertPeriodMap {
  formattedPeriod: AlertPeriodFormatted;
  rawPeriod: AlertPeriod;
}

export interface AlertRepeatMap {
  formattedRepeat: AlertRepeatFormatted;
  rawRepeat: AlertRepeat;
}

export interface AlertThresholdMap {
  formattedThreshold: AlertThresholdFormatted;
  rawThreshold: AlertThreshold;
  postVerb: 'than' | 'to';
}

export interface Alerts {
  agreements: Agreement[];
  on: boolean;
}

export interface APIAirAlert {
  name: string;
  userId: number;
  alertId: number;
  phoneNumber: string | null;
  EmailAddress: string | null;
  TargetGroups: number;
  alertClassId: number;
  startDate: string;
  endDate: string | null;
  interval: number;
  timeOffset: number;
  ZephyrNumber: number;
  targets: APIAirAlertTarget[];
  jsonOutput: string;
}

export interface AlertModel {
  AlertId: number | null;
  Name: string | null;
  AlertClassId: number;
  Organisation: string | null;
  FirstName: string;
  LastName: string;
  PhoneNumber: string | null;
  EmailAddress: string | null;
  StartDate: string;
  EndDate: string | null;
  Interval: number;
  TimeOffset: number;
  // TBD
  Targets: APIAirAlertTarget[];
  JsonInput: SpeciesData[];
}

export interface APIAirAlertPost {
  Alerts: AlertModel;
}

export interface APIAirAlertParameter {
  AlertObject?: number | null;
  Name: string;
  IsInput: boolean;
  Value: number | null;
  StringValue: SpeciesDataIdentifiers | string | null;
  Units: null;
}

export interface APIAirAlertTarget {
  targetId: number;
  firstName: string;
  lastName: string;
  emailAddress: string | null;
  phoneNumber: string | null;
  organisation: string | null;
  alertId: number | null;
}

export interface APIZephyrList {
  query: string;
  userName: string;
  usersZephyrs: {
    current: {
      [key: number]: Zephyr;
    };
    past: {
      [key: number]: Zephyr;
    };
  };
}

export interface DisplayConfig {
  met?: Met[];
  tabs: Tab[];
  sources: Sources[];
  speciesExtent: SpeciesExtent[];
  windCategories: WindCategory[];
}

export interface AppIntro {
  body: string;
  header: string;
  on: boolean;
  tourLink: boolean;
}

export interface AQ101 {
  on: boolean;
  sectionId: string;
}

export interface AnnualAvgList {
  species: string;
  units: string;
  latlon: any;
  values_timestamps: { timestamp: string; units: string; value: number }[];
}
export interface AnnualAvgResultData {
  meta: {
    keyword: string;
    speciesList: string[];
    timestamps: string[];
  };
  results: any;
}

// eslint-disable-next-line no-shadow
export enum StateStatus {
  idle = 'idle',
  loading = 'loading',
  failed = 'failed',
  succeeded = 'succeeded',
}

export interface MapAirPointValueHistoryData {
  saValue: SAData | null;
  valueHistory: TodayData | null;
  status: StateStatus;
}

export interface AnnualAvgData {
  list: AnnualAvgList | null;
  raw: AnnualAvgResultData | null;
  chart: { labels: Date[]; datasets: LineChartData[] };
}

export interface AnnualAvgTabs {
  chart: boolean;
  list: boolean;
  [key: string]: boolean;
}

export interface AQLatestMeasurementDataTabs {
  zephyr: boolean;
  met: boolean;
  other: boolean;
  [key: string]: boolean;
}

export interface AQModelDataTabs {
  aqmSA: boolean;
  aqmTemp: boolean;
  aqmWind: boolean;
  [key: string]: boolean;
}

export interface AQOptionsMap {
  label: string;
  value: AQOptionsValues;
}

export interface AnnualAvgTabMap {
  label: string;
  value: string;
}

export interface AQTabs {
  aqData: boolean;
  aqColourscale: boolean;
  aqModelData: boolean;
  annualAvg: boolean;
  [key: string]: boolean;
}

export interface AreaConfig {
  areaSize: number | null;
  centreHdms: string | null;
  latLngGeometry: Geometry | null;
}

export interface Aside {
  on: boolean;
}

export interface AsideOverlaysSpeciesMap {
  speciesMap: { type: UnitOverlayTypes; label: string }[];
}
export interface FleetManagementSpeciesMap {
  speciesMap: {
    type: UnitOverlayTypes;
    label: string;
    checked: boolean;
    zType: number;
  }[];
}

export interface AsideUnitMetricsMap {
  metricsFrequencyMap: { item: string; header: string }[];
  metricsStatusMap: { item: string; header: string; unit: string }[];
}

export interface Auth {
  token: string | null;
  isAuthenticated: boolean;
  loading: boolean;
  user: string | null;
  userInfo: UserInfo;
  loginFailed: boolean;
  bearerToken: string | null;
}

export interface AuthProps {
  internal: boolean;
  isAuthenticated: boolean;
  password: string;
  queryString: UrlQueryStringParams | null;
  username: string;
}

export interface AveragingPeriod {
  labelHTML: AveragingLabels;
  averagingOption: AveragingOptions;
}

export interface AveragingPeriods {
  periods: AveragingPeriod[];
}

export interface BarChartDataPoints {
  label: string;
  y: number;
}

export interface CardinalDirections {
  minDegrees: number;
  dirName: string;
}

export interface ChartData {
  labels: Date[];
  datasets: LineChartData[];
}

export interface ComponentContent {
  [key: string]: string;
}

export interface ContactForm {
  formData: {
    details: string;
    email: string;
    enquiry: string;
    comments: string;
    company: string;
    firstName: string;
    lastName: string;
  };
  userMessage: string;
}

export interface ContactFormMappair {
  formData: {
    area: AreaConfig;
    comments: string;
    company: string;
    email: string;
    firstName: string;
    lastName: string;
    pollutants: string[];
    [key: string]:
    | string
    | number
    | AreaConfig
    | ContactFormSelection[]
    | string[];
  };
  userMessage: string;
}

export interface ContactFormSelection {
  value: string;
  label: string;
}

export interface ContactFormSelectionLookup {
  [key: string]: ContactFormSelection[];
}

export interface ContactFormZephyr {
  formData: {
    firstName: string;
    lastName: string;
    email: string;
    company: string;
    zephyrs: number;
    powerOption: string;
    species: ContactFormSelection[];
    [key: string]: string | number | ContactFormSelection[];
  };
  userMessage: string;
}

export interface CookieSettings {
  name: string;
  expiry: number;
}

export interface Colour {
  r: string;
  g: string;
  b: string;
  [key: string]: string;
}

export interface Config {
  machine: string;
  alertsAPI: string | undefined;
  mappairAPI: string | undefined;
  zephyrAPI: string | undefined;
  zephyrDataAPI: string;
  vZephyrAPI: string | undefined;
  mappairTiledAPI: string | undefined;
  masterAuthAPI: string | undefined;
  myAirApi: string | undefined;
  enableGridDragAndDrop?: any;
  enableAutoLoadFirstView?: boolean;
  sentryENABLED?: boolean;
  sentryDSN?: string;
  sentryRELEASE?: string;
  sentryENVIRONMENT?: string;
}

export interface VZephyrsConfig {
  active: number;
  max: number;
  new30Days: number;
  new30DaysMax: number;
}

export interface Credentials {
  token: string;
  user: string;
  key: string;
}

export interface CsvData {
  'Timestamp(UTC)': string;
  [key: string]: number | string | null;
}

export interface DataConfig {
  averagingChain: number;
  ctv: string;
}

export interface Days {
  [key: number]: DayOptions;
}

export interface DropdownSelection {
  label: string;
  value: string;
}

export interface EnhancedThreshold {
  name: string;
  min: number;
  r: number;
  g: number;
  b: number;
  max: number | null;
}

export interface ExternalMetData {
  dateTime: string;
  humidity: number;
  locationId: number;
  locationLat: number;
  locationLon: number;
  siteId: number;
  siteLat: number;
  siteLon: number;
  siteName: string;
  temp: number;
  windDir: number;
  windSpeed: number;
  [key: string]: number | string;
}

export interface Feedback {
  idx: 0;
  on: boolean;
  oneTimeAutomation: boolean;
  questions: FeedbackQuestion[];
}

export interface FeedbackQuestion {
  comments: boolean;
  commentsQuestion: string;
  max: number;
  min: number;
  mufId: number;
  name: string;
  order: number;
  question: string;
}

export interface GetZephyrHistoricalData {
  zephyrHistory: ZephyrHistory | null;
  loading: boolean;
  zType?: number | null;
  isHistoricalDataNull?: boolean;
}

export interface getZephyrMeasurementData {
  zephyrHistory: ZephyrHistory | null;
  loading: boolean;
}

export interface GetZephyrs {
  zephyrs: Zephyr[];
  loading: boolean;
}

export interface GoogleLocation {
  address_components: any;
  formatted_address: string;
  types: GoogleLocationTypes[];
}

export interface GooglePlace {
  description: string;
  place_id: string;
}

export interface GtmConfig {
  gtmId: string;
  auth: string;
  preview: string;
}

export interface GtmEventIdentifiers {
  [key: string]: string;
}

export interface GtmEvents {
  [key: string]: {
    category: string;
    action: string;
    label: string;
    value: number;
  };
}

export interface HighDefinitionPointsGroup {
  users: string[];
  layers: HighDefinitionPointsLayer[];
}

export interface HighDefinitionPointsLayer {
  curOverlay: string;
  highDefRef: string;
  extent: number[][];
}

export interface Keys {
  [key: string]: string;
}

export interface LatestPublicMeasurement {
  identifier: SpeciesDataIdentifiers;
  HTMLLabel: string;
  HTMLUnitLabel: string;
  value: number;
}

export interface LineChartData {
  borderDash: number[];
  borderDash2: BorderDashStrings;
  data: number[] | string[] | null[];
  defaultColour: string;
  featureColour: string;
  hidden: boolean;
  label: string;
  species: SpeciesDataIdentifiers;
  speciesLabel: SpeciesDataLabels | any;
  speciesObj: Species;
  unitId: number;
  unitNameLabel: string;
}

export interface LineChartDataPoints {
  x: Date;
  y: number | null;
}

export interface LineChartDataEnriched {
  axisYType?: string;
  axisYIndex?: number;
  click: Function;
  cursor: CursorOptions;
  dataPoints: LineChartDataPoints[];
  label: SpeciesDataLabels;
  lineColor: string;
  lineDashType: BorderDashStrings;
  markerBorderColor: string;
  markerBorderThickness: number;
  markerColor: string;
  markerSize: number;
  name: string;
  type: ChartOptions;
  visible: boolean;
  xValueFormatString: string;
}

export interface Location {
  location: LocationCoordinates;
}

export interface LocationCoordinates {
  0: number;
  1: number;
}

export interface Link {
  content?: string;
  precedingContent?: string;
  target: LinkTargetOptions;
  url: string;
}

export interface Loading {
  aurnLastHour: boolean;
  initApp: boolean;
  zephyrLastHour: boolean;
}

export interface MappAirFilters {
  selectedFilters: MappAirSelectedFilter[];
  showMappAirFilters: boolean;
}

export interface MappAirFilterToggles {
  value: MappAirFilterOverlayToggles;
  label: string;
}

export interface MappAirSelectedFilter {
  name: UnitOverlayTypes;
  showAllLabels: boolean;
  showSelectedLabel: boolean;
  [key: string]: boolean | UnitOverlayTypes;
}

export interface MapsLocationLoaded {
  mapsLoaded: boolean;
}

export interface Met {
  group: string;
  species: MetSpecies[];
}

export interface MetSpecies {
  dataIdentifier: SpeciesDataIdentifiers;
  HTMLExposedUnitLabel: SpeciesUnits;
  HTMLLabel: string;
  HTMLShortUnitLabel: SpeciesUnitsWrapped;
  HTMLUnitLabel: SpeciesDataLabels;
}

export interface Modal {
  contact: boolean;
  data: boolean;
  mappairQuote: boolean;
  mappairTimeseries: boolean;
  zephyrQuote: boolean;
}

export interface ObjectMap {
  [key: string]: string;
}

export interface Overlay {
  accessGranted: boolean;
  isMappairOverlay: boolean;
  isSAOverlay: boolean;
  name: string;
  nameHTML: string;
  speciesIdentifier: string;
  species: string;
  keyword: string;
  layername?: string;
  displayOrder?: number;
}

export interface Overlays {
  [index: number]: { name: string; nameHTML: string };
}

export interface Paths {
  [key: string]: string;
}

export interface Periods {
  [key: string]: { quantity: number; unit: string };
}

export interface PlaceholderAxis {
  labelFontSize: number;
  title: string;
  tickLength: number;
  lineThickness: number;
  margin: number;
}

export interface PlaceholderAxisConfig {
  idx: number;
  maximum: number | null;
  minimum: number | null;
  title: string;
}

export interface PlacePrediction {
  description: string;
  placeId: string;
}

export interface PublicAdvice {
  advice: string;
  advice_sensitive: string;
  behavioural_change: string;
  label: string;
  lower_limit_value: number;
  species: string;
}

export interface publicAdviceArr {
  advice: PublicAdvice[][];
}

export interface PointValues {
  colour: string;
  coordinates: LocationCoordinates;
  hdms: string;
  hdRef: string | null;
  namedLocation: GoogleLocation[] | null;
  pixels: number[];
  position: number[];
  saValue: SAData | null;
  // Used for the PP to display advice for aqi
  secondaryValue: number | null;
  value: number | null;
  valueHistory: TodayData | null;
}

export interface PolarPlots {
  windRose: string;
  [key: string]: string;
}

export interface PolarPlotData {
  ws: number[];
  wd: number[];
  [key: string]: number[];
  Authorization?: any;
}

export interface ReduxState {
  [x: string]: any;
  airAlerts: AirAlerts;
  alerts: Alerts;
  aq101: AQ101;
  aside: Aside;
  auth: Auth;
  dataConfig: DataConfig;
  draggedUnit: Zephyr | null;
  feedback: Feedback;
  getStyleGroups: Stylegroups;
  getZephyrs: GetZephyrs;
  getZephyrHistoricalData: getZephyrMeasurementData;
  loading: Loading;
  mappAirFilters: MappAirFilters;
  mapsLocationLoaded: MapsLocationLoaded;
  modal: Modal;
  publicAdvice: publicAdviceArr;
  queryStringParams: UrlQueryStringParams;
  showAQMALayer: ShowAQMALayer;
  setAveragingPeriods: AveragingPeriods;
  setDisplayConfig: DisplayConfig;
  setLocation: Location;
  setOverlays: Overlay[];
  setThresholdTab: ThresholdTab;
  setTimePeriods: TimePeriods;
  setTodayData: TodayData | null;
  setUnitLocation: Location;
  setUserConfig: UserConfig;
  setVersions: Version[];
  setZephyr: SetZephyr;
  tour: Tour;
  annualAvgData: AnnualAvgData;
  locale: any;
  modularFeatures: ModularFeatures;
  unitHistoriesOptions: UnitHistoriesOptions;
  thresholds: ThresholdsList;
  
  analysisTourOpen: boolean;
  alertsTourOpen: boolean;
  selectChartModeOpen: boolean;
  selectedChartViewMode: 'deviceView' | 'networkView' | 'comparisonView';
}

export interface RequestError {
  errorType: string | number | null;
  unitId: number;
  unitName: string;
}

export interface SAChartData {
  data: {
    color: string;
    cursor: CursorOptions;
    dataPoints: BarChartDataPoints[];
    dataPointMinWidth: number;
    type: ChartOptions;
  }[];
  meta: {
    total: number;
  };
}

export interface SAData {
  auxinfo: { species: string; layer_names: string[][] }[];
  meta: {
    speciesList: string[];
    timestamps: string[];
  };
  results: SAResult[];
}

export interface SADataSet {
  data: number[] | string[] | null[];
  backgroundColor: string[];
  borderWidth: number;
}

export interface SAResult {
  species: string;
  units: string;
  values_coordinates: SAResultData[];
}

export interface SAResultData {
  latlon: { lat: number; lon: number };
  values_timestamps: { timestamp: string; value: number }[];
}

export interface SecondaryAxisConfig {
  idx: number;
  maximum: number | null;
  minimum: number | null;
  title: string;
  void?: boolean;
}

export interface SetZephyr {
  editMode: boolean;
  set: boolean;
  zephyr: Zephyr;
  hasInitialSet: boolean;
}

export interface ShowAQMALayer {
  opacity: number;
  overlay: string;
  mappairPointModeOn: boolean;
  showAQMALayer: boolean;
  showSchoolsLayer: boolean;
  showSmokeControlZones: boolean;
  showOverlayLayer: boolean;
  showSatelliteLayer: boolean;
  showOSM: boolean;
  showAALayer: boolean;
  annualAverage: number | null;
}

export interface Slot {
  HTMLLabel: string;
  label: string;
}

export interface SlotsInfo {
  slotA: ZephyrConfigSlot | null;
  slotB: ZephyrConfigSlot | null;
  [key: string]: ZephyrConfigSlot | null;
}

export interface Sources {
  colour: Colour;
  HTMLExposedUnitLabel: SpeciesUnits;
  HTMLShortUnitLabel: SpeciesUnitsWrapped;
  source: SourceOptions;
}

export interface Species {
  colour: Colour[];
  dataIdentifier: SpeciesDataIdentifiers;
  dataIdentifiers: SpeciesDataIdentifiers[];
  HTMLExposedUnitLabel: SpeciesUnits;
  HTMLLabel: string;
  HTMLUnitLabel: SpeciesDataLabels;
  HTMLShortUnitLabel: SpeciesUnitsWrapped;
  label: string;
  // Scales
  maximum: number | boolean;
  minimum: number | boolean;
  // Conversions
  conversionDivider: number | boolean;
  conversionMultiplier: number | boolean;
  secondaryAxis: boolean;
}

export interface SpeciesExtent {
  dataIdentifier: SpeciesExtentIdentifiers;
  species: SpeciesExtentInd[];
}
export interface SpeciesExtentInd {
  HTMLLabel: string;
  HTMLUnitLabel: SpeciesDataLabels;
  HTMLShortUnitLabel: string;
}

export interface SpeciesExtentIndEnriched {
  dataIdentifier: SpeciesDataIdentifiers;
  HTMLLabel: string;
  HTMLUnitLabel: SpeciesDataLabels;
  HTMLShortUnitLabel: string;
  speciesExtentIdentifier: SpeciesExtentIdentifiers;
}

export interface SpeciesMap {
  [key: string]: string;
}

export interface StrippedTab {
  group: SpeciesGroupOptions;
  HTMLGroupLabel: string;
  curSlot?: SlotOptions | null;
}

export interface Stylegroup {
  displayedDescriptionHTML: string;
  displayedDescriptionNameHTML?: string;
  displayedName: string;
  displayedNameHTML: string;
  name: string;
  thresholds: { [key: string]: Threshold[] };
}

export interface Stylegroups {
  styleGroups: Stylegroup[];
}

export interface Tab {
  group: SpeciesGroupOptions;
  context: ContextOptions;
  contextLabel: string;
  HTMLGroupLabel: string;
  species: Species[];
}

export interface Threshold {
  b: number;
  g: number;
  r: number;
  min: number;
  name: string;
}

export interface TilingGroup {
  layers: TilingLayer[];
  name: string;
}

export interface TilingLayer {
  extent: number[][] | undefined;
  layer: string;
  level: number;
  name: string;
  owner: string;
}

export interface Timezone {
  endLocal: string;
  endUTC: string;
  startLocal: string;
  startUTC: string;
  timezone: string;
}

export interface TimePeriod {
  labelHTML: PeriodLabels;
  timeOption: PeriodOptions;
}

export interface TimePeriods {
  periods: TimePeriod[];
}

export interface ThresholdTab {
  tab: string;
}

export interface TodayData {
  meta: {
    species_list: string[];
    timestamps: string[];
  };
  results: TodayDataset[];
  location: {
    latlon: LocationCoordinates;
    desc: string;
  };
}

export interface TodayDataset {
  species: string;
  units: SpeciesUnits;
  values_coordinates: {
    latlon: {
      lat: number;
      lon: number;
    };
    values_timestamps: {
      advice: string;
      advice_sensitive: string;
      behavioural_change: string;
      label: string;
      timestamp: string;
      value: number;
    }[];
  }[];
}

export interface TodayDatasetPackaged {
  advice: string;
  advice_sensitive: string;
  behavioural_change: string;
  day: string;
  label: string;
  species: string;
  timestamp: string;
  unit: SpeciesUnits;
  value: number;
}

export interface TodayDatasetsPackaged {
  [key: string]: any;
  data: TodayDatasetPackaged[][];
  location: {
    desc: string;
    latlon: LocationCoordinates;
  };
  species: string[];
}

export interface TodayInfoSection {
  customTitle: string;
  postText: string;
  preText: string;
  // This links to an AQ101 section id
  spanSectionLink: string;
  spanText: string;
}

export interface TodaySubspeciesMap {
  label: string;
  speciesRef: string;
}

export interface Tour {
  clickMap: boolean;
  openAside: boolean;
  openAsideFilter: boolean;
  openAQIndex: {
    openAQData: boolean;
    openAQColourscale: boolean;
    openAQMAIndex: boolean;
    openAQModelData: boolean;
  };
  openHelp: boolean;
  openLocationSearch: boolean;
  openTour: boolean;
}

export interface UnitSelection {
  HTMLLabel: UnitSelectionLabels;
  type: UnitSelectionTypes;
}

export interface UnitSelections {
  unitSelectionOptions: UnitSelection[];
}

export interface UnitStatus {
  HTMLLabel: string;
  type: string;
}

export interface UrlQueryStringParams {
  ctv: string;
  diagnostics: string;
  [id: string]: string;
}

export interface UserConfig {
  latestVersionViewed: number;
  showTour: boolean;
}

export interface UserInfo {
  appIntro: AppIntro | null;
  authorityLink: string;
  caching: number;
  info: string;
  isInternal: boolean;
  isPublic: boolean;
  logo: string | null;
  maxLat: number;
  maxLng: number;
  minLat: number;
  minLng: number;
  projectLink: string;
  startingLat: number;
  startingLng: number;
  startingZoom: number;
  subtitle: string;
  today: boolean;
  todayCustomInfo: TodayInfoSection | null;
  todaySpecies: MappairSpecies | null;
  title: string;
  showDetailedZephyrInfo: boolean;
  showSmileyFaces: boolean;
  userCustomInfo: UserInfoSection | null;
  userStandardInfo: UserInfoSection | null;
  mappair: boolean;
}

export interface UserInfoSubSection {
  id: string;
  header: string;
  html: string;
}

export interface UserInfoSection {
  sections: UserInfoSubSection[];
}

export interface Version {
  versionId: number;
  versionNotes: string;
  versionRef: string;
}

export interface WindCategory {
  colour: Colour;
  minVal: number;
  name: string;
  unit: SpeciesUnits;
}

export interface WindPackagedData {
  dateTime: string[];
  direction: number[];
  speed: number[];
  [key: string]: string[] | number[];
}

export interface WindRoseData {
  hoverinfo: string;
  marker: { color: string };
  name: string;
  r: number[];
  theta: string[];
  type: any;
  unit: SpeciesUnits;
}

export interface VirtualZephyr {
  [x: string]: any;
  id: number;
  name: string;
  description: string | null;
  latlon: VirtualZephyrLocation;
  sampleFreqMins: number;
  startdt: string;
  endDt: string;
}

export interface VirtualZephyrData {
  id: number;
  name: string;
  description: string | null;
  latlon: VirtualZephyrLocation;
  outputs: VirtualZephyrResults;
}

export interface VirtualZephyrResults {
  results: any[];
}

export interface VirtualZephyrLocation {
  latitude: string;
  longitude: string;
}

export interface Zephyr {
  HasMetStation?: 0 | 1;
  HasCertificate: 0 | 1 | null;
  averageOfLastHourOfData: ZephyrLastHourInd | null | any;
  SampPeriod: number | null;
  alias: string;
  backLogCount: number | null;
  batteryCharge: number | null;
  batteryVoltage: string | null;
  externalMetData: ExternalMetData | null;
  externalVoltage: string | null;
  firmwareVersion: string | null;
  id_location: number | null;
  id_pod: number;
  lastConnectionDT: string | null;
  lastDataDT: string | null;
  latLngAltered: string | null;
  latitude: string;
  location: string;
  locationStartTimeDate: string | null;
  locationType: number | null;
  longitude: string;
  measurementCount: number | null;
  mobileLocation: number | null;
  name: string;
  serialNumber: string | null;
  slotA_SN: string | null;
  slotA_start: string | null;
  slotB_SN: string | null;
  slotB_start: string | null;
  slotsInfo: SlotsInfo;
  type: ZephyrTypes;
  userEndTimeDate: string | null;
  userStartTimeDate: string;
  zNumber: number;
  [key: string]: any;
}

export enum ZephyrTypes {
  zephyr = 0,
  aurns = 1,
  virtual = 100,
}

export interface ZephyrAlert {
  battery: boolean;
  charge: boolean;
  data: boolean;
  power: boolean;
  solar: boolean;
  [key: string]: boolean;
}

export interface ZephyrAlertUnit {
  alertStatus: boolean;
  HasCertificate: 0 | 1 | null;
  averageOfLastHourOfData: ZephyrLastHourInd | null;
  alias: string | null;
  backLogCount: number | null;
  batteryCharge: number | null;
  batteryVoltage: string | null;
  externalMetData: ExternalMetData | null;
  externalVoltage: string | null;
  firmwareVersion: string | null;
  id_location: number | null;
  id_pod: number;
  lastConnectionDT: string | null;
  lastDataDT: string | null;
  latLngAltered: string | null;
  latitude: string | null;
  location: string;
  locationStartTimeDate: string | null;
  locationType: number | null;
  longitude: string | null;
  measurementCount: number | null;
  mobileLocation: number | null;
  name: string;
  serialNumber: string | null;
  slotA_SN: string | null;
  slotA_start: string | null;
  slotB_SN: string | null;
  slotB_start: string | null;
  slotsInfo: SlotsInfo | null;
  type: number;
  userEndTimeDate: string | null;
  userStartTimeDate: string;
  zNumber: number;
  [key: string]: any;
}

export interface ZephyrAllocation {
  end: string;
  start: string;
}

export interface ZephyrConfig {
  measurementPeriodSecs: number;
  slotsInfo: {
    [key: string]: ZephyrConfigSlot;
  };
  uploadPeriodMeasurements: number;
}

export interface ZephyrConfigSlot {
  CommercialName: string;
  FirmICType: number;
  ICType: number;
  InternalName: string;
  Serial: string;
  ShortCode: string;
  species: SpeciesExtentIdentifiers[];
  Version: string;
  xFirmICType: string;
}

export interface ZephyrLastHourInd {
  O3: number | null;
  PM2p5: number | null;
  CO2: number | null;
  humidity: number | null;
  CO: number | null;
  TVOC: number | null;
  H2S: number | null;
  PM: number | null;
  AQI: number | null;
  SO2: number | null;
  PM10: number | null;
  NO: number | null;
  NO2: number | null;
  PM1: number | null;
  temp: number | null;
  metWS: number | null;
  metWD: number | null;
  metT: number | null;
  metH: number | null;
  [key: string]: number | null;
}

export interface ZephyrLocation {
  desc: string;
  lng: string;
  latLngAltered: string;
  locationType: number;
  id: string;
  isMobile: number;
  lat: string;
  since: string;
}

export interface ZephyrLocationShort {
  locationId: number;
  lat: string;
  lon: string;
  Authorization?: string;
}

export interface ZephyrConnection {
  averageOfLastHourOfData: ZephyrLastHourInd;
  backlogCount: number | null;
  batteryCharge: number | null;
  batteryVoltage: number | null;
  cartridgeAge_months_slotA: number | null;
  cartridgeAge_months_slotB: number | null;
  extVoltage: number | null;
  lastDataDT: string | null;
  lastDT: string | null;
  measurementCount: number | null;
  [key: string]: number | string | ZephyrLastHourInd | null;
}

export interface ZephyrHistory {
  [key: string]: ZephyrHistoryInd;
  Unaveraged: ZephyrHistoryInd;
  'Daily average at midnight': ZephyrHistoryInd;
  '15 min average on the quarter hours': ZephyrHistoryInd;
  '8 hour average at midnight and 8am and 4pm': ZephyrHistoryInd;
  'Hourly average on the hour': ZephyrHistoryInd;
}

export interface ZephyrHistoryInd {
  // Slot
  [key: string]: {
    // Species
    [key: string]: {
      header: {
        key: string;
        group: string;
        units: string;
        HTMLLabel: string;
        CSVOrder: number;
        label: string;
      };
      allNull: boolean;
      data_hash: string;
      data: any[];
      tzHistory?: Timezone[] | null;
    };
  } | null;
}

export interface ZephyrHistoryIndSlotData {
  [key: string]: {
    header: {
      key: string;
      group: string;
      units: string;
      HTMLLabel: string;
      CSVOrder: number;
      label: string;
    };
    allNull: boolean;
    data_hash: string;
    data: any[];
    tzHistory?: Timezone[] | null;
  };
}

export interface ZephyrHistoryWithId {
  [key: number]: ZephyrHistory;
}

export interface ZephyrStatInd {
  distanceTravelledKm: number | null;
  connectionCount: number | null;
  measurementCount: number | null;
}

export interface ZephyrStats {
  total: ZephyrStatInd;
  day: ZephyrStatInd;
}

// Types
export type AlertErrors =
  | 'sourceSelect'
  | 'dataSelect'
  | 'name'
  | 'email'
  | 'concentrationLimit'
  | 'phonenumber'
  | 'communication';

export type AlertPeriod = 'instant' | '15' | '30' | '60' | '480' | '1440' | '';

export type AlertPeriodFormatted =
  | 'Instant'
  | '15 min'
  | '30 min'
  | '60 min'
  | '8hr'
  | '24hr'
  | 'Select';

export type AlertRepeat = 'never' | 'hourly' | 'daily' | '';

export type AlertRepeatFormatted = 'Never' | 'Hourly' | 'Daily' | 'Select';

export type AlertThreshold =
  | 'greater'
  | 'greaterOrEqual'
  | 'lessOrEqual'
  | 'less'
  | '';

export type AlertThresholdFormatted =
  | 'greater'
  | 'greater or equal'
  | 'less or equal'
  | 'less'
  | 'Select';

export type AQOptionsValues =
  | 'zephyr'
  | 'met'
  | 'other'
  | 'aqmTemp'
  | 'aqmSA'
  | 'aqmWind';

export type AveragingLabels =
  | 'None'
  | '15 Min'
  | '1 Hour'
  | '8 Hour'
  | '24 Hour';

export type AveragingOptions =
  | 'Unaveraged'
  | 'Daily average at midnight'
  | '15 min average on the quarter hours'
  | '8 hour average at midnight and 8am and 4pm'
  | 'Hourly average on the hour';

export type BorderDashStrings =
  | 'solid'
  | 'shortDot'
  | 'shortDash'
  | 'longDashDot'
  | 'longDash';

export type CursorOptions = 'default' | 'pointer' | 'crosshair';

export type ChartOptions = 'bar' | 'line' | 'spline';

export type ContextOptions = 'zephyr' | 'met';

export type DayOptions = 'Mon' | 'Tue' | 'Wed' | 'Thu' | 'Fri' | 'Sat' | 'Sun';

export type Directions =
  | 'N'
  | 'NNE'
  | 'NE'
  | 'ENE'
  | 'E'
  | 'ESE'
  | 'SE'
  | 'SSE'
  | 'S'
  | 'SSW'
  | 'SW'
  | 'WSW'
  | 'W'
  | 'WWW'
  | 'NW'
  | 'NNW'
  | '';

export type DownloadOptions = 'csv' | 'kml';

// Docs: https://developers.google.com/maps/documentation/geocoding/intro#Types
export type GoogleLocationTypes =
  | 'street_address'
  | 'route'
  | 'intersection'
  | 'political'
  | 'country'
  | 'administrative_area_level_1'
  | 'administrative_area_level_2'
  | 'administrative_area_level_3'
  | 'administrative_area_level_4'
  | 'administrative_area_level_5'
  | 'colloquial_area'
  | 'locality'
  | 'sublocality'
  | 'neighbourhood'
  | 'premise'
  | 'subpremise'
  | 'plus_code'
  | 'postal_code'
  | 'natural_feature'
  | 'airport'
  | 'park'
  | 'point_of_interest';

export type LinkTargetOptions = '_blank' | '_self' | '_parent' | '_top';

export type MappAirFilterOverlayToggles = 'showAllLabels' | 'showSelectedLabel';

export type MappairSpecies = 'no2' | 'pm2p5' | 'aqi_uk';

export type PeriodLabels =
  | '24 Hours'
  | '48 Hours'
  | 'Week'
  | 'Month'
  | 'Custom'
  | 'Yesterday'
  | '2 days'
  | '3 days';

export type PeriodOptions = '24' | '48' | 'week' | 'month' | 'custom' | '72';

export type SlotOptions = 'slotA' | 'slotB' | 'head' | '';

export type SlotLabels = 'Slot A' | 'Slot B' | 'head';

export type SourceOptions =
  | 'agric'
  | 'domcom'
  | 'energypro'
  | 'industrialcom'
  | 'industrialpro'
  | 'nature'
  | 'offshore'
  | 'otherstransport'
  | 'roadtransport'
  | 'solvent'
  | 'waste';

export type SpeciesDataIdentifiers =
  | 'NO'
  | 'NO2'
  | 'O3'
  | 'particulatePM1'
  | 'particulatePM25'
  | 'particulatePM10'
  | 'PM'
  | 'CO2'
  | 'SO2'
  | 'H2S'
  | 'CO'
  | 'TVOC'
  | 'tempC'
  | 'ambHumidity'
  | 'AQI'
  // Met
  | 'temp'
  | 'humidity'
  | 'windDir'
  | 'windSpeed'
  | 'Charge';

export type SpeciesDataLabels =
  | 'NO(μg/m³)'
  | 'NO₂(μg/m³)'
  | 'O₃(μg/m³)'
  | 'PM₁(μg/m³)'
  | 'PM₂.₅(μg/m³)'
  | 'PM₁₀(μg/m³)'
  | 'CO₂(ppm)'
  | 'SO₂(μg/m³)'
  | 'H₂S(μg/m³)'
  | 'CO(mg/m³)'
  | 'TVOCs(ppb)'
  | 'Temperature(°C)'
  | 'Humidity(%)'
  | 'AQI'
  // Met
  | 'Wind Direction(°)'
  | 'Wind Speed(m/s)'
  | 'Charge(%)';

export type SpeciesExtentIdentifiers =
  | 'NO'
  | 'NO2'
  | 'O3'
  | 'PM'
  | 'CO2'
  | 'SO2'
  | 'H2S'
  | 'CO'
  | 'TVOC'
  | 'AT'
  | 'ARH'
  | 'AQI'
  | 'Charge';

export type SpeciesGroupOptions =
  | 'NO2'
  | 'PM'
  | 'PM2.5'
  | 'CO2'
  | 'Enhanced'
  | 'TVOC'
  | 'temp';

export type SpeciesUnits =
  | 'μg/m³'
  | 'mg/m³'
  | 'ppb'
  | 'ppm'
  | '°C'
  | '%'
  | 'tonnes/annum'
  | 'Max:10'
  // Met
  | 'm/s'
  | '°';

export type SpeciesUnitsWrapped =
  | '(μg/m³)'
  | '(mg/m³)'
  | '(ppb)'
  | '(ppm)'
  | '(°C)'
  | '(%)'
  | '(tonnes/annum)'
  | '(Max:10)'
  // Met
  | '(m/s)'
  | '(°)'
  | '';

export type UnitOverlayTypes = 'aurn' | 'zephyr' | 'virtual';

export type UnitSelectionTypes = 'specific' | 'non-specific';

export type UnitSelectionLabels =
  | 'Specific Zephyr(s)'
  | 'All Zephyrs in my inventory';

export type UrlQueryStringOptions = 'ctv' | 'diagnostics' | '';

export interface translationOption {
  value: string;
  label: string;
  icon: string;
}

export interface LonLatXY {
  xMin: number;
  yMin: number;
  xMax: number;
  yMax: number;
}

export interface tileDimensions {
  width: number;
  height: number;
}

export interface YearObj {
  value: string;
  label: number;
}

export interface TimeSliderOption {
  id: number;
  d: string;
  text: string;
}

export interface UnitHistoriesOptions {
  unitHistories: any;
  isUnitHistoriesLoading: boolean;
  thresholdLimits: ThresholdLimits;
  zephyrCoveragesList: ZephyrCoverages[];
  isDataAvailabilityChartLoading: boolean;
  dseOn: boolean;
  requests: number;
  nullableUnits: number[];
  toProceed: boolean | null;
  viewLoaded: boolean;
  isCartridgeDataLoading: boolean | null;
  isWidgetLibraryVisible: boolean;
}

export interface ZephyrAnalytics extends Zephyr {
  isSelected: boolean;
}

export interface Pollutant {
  dataIdentifier: string;
  speciesExtentIdentifier: string;
  HTMLLabel: string;
  HTMLUnitLabel: string;
  HTMLShortUnitLabel: string;
  isChecked?: boolean;
  isSelected?: boolean;
}

export interface ThresholdProperties {
  title: string;
  value: number | null;
  id?: number | null;
}

export interface ThresholdLimits {
  thresholds: {
    lowerThreshold: ThresholdProperties;
    upperThreshold: ThresholdProperties;
  },
  hidden: boolean
}

export interface ZephyrCoverages {
  name?: string;
  zephyrNumber?: number;
  isSelected?: boolean;
  requestMade?: boolean;
  coverageData?: any;
  zType?: number;
}

export interface AveragingChain {
  [key: string]: number
};

export interface ThresholdsList {
  gases: ThresholdLimits,
  particulate: ThresholdLimits,
  networkViewGases: ThresholdLimits,
  networkViewParticulates: ThresholdLimits,
  [key: string]: ThresholdLimits
};

export interface PollutantLimit {
  id: number,
  pollutant: string,
  subscript: string,
  value: number
};
