import { SET_MODULAR_FEATURES } from "../actions/types";

export interface ModularFeatures {
    timeSlider: {
        isPrivate: boolean,
        isPublic: boolean,
    },
    fleetManagement: {
        isPrivate: boolean,
        isPublic: boolean,
    },
    alerts: {
        isPrivate: boolean,
        isPublic: boolean,
    },
    schools: {
        isPrivate: boolean,
        isPublic: boolean,
    },
    smokeControlZones: {
        isPrivate: boolean,
        isPublic: boolean,
    },
    annualAverages: {
        isPrivate: boolean,
        isPublic: boolean,
    },
    dseIntegration: {
        isPrivate: boolean,
        isPublic: boolean,
    },
    dataAnalytics: {
        isPrivate: boolean,
        isPublic: boolean,
    },
}

interface SetModularFeatures {
    type: typeof SET_MODULAR_FEATURES;
    payload: ModularFeatures;
}

const initState: ModularFeatures = {
    timeSlider: {
        isPrivate: true,
        isPublic: false,
    },
    fleetManagement: {
        isPrivate: true,
        isPublic: false,
    },
    alerts: {
        isPrivate: true,
        isPublic: false,
    },
    schools: {
        isPrivate: true,
        isPublic: true,
    },
    smokeControlZones: {
        isPrivate: true,
        isPublic: true,
    },
    annualAverages: {
        isPrivate: true,
        isPublic: false,
    },
    dseIntegration: {
        isPrivate: false,
        isPublic: false,
    },
    dataAnalytics: {
        isPrivate: true,
        isPublic: true,
    },
}

// Reducer
export default function (state = initState, action: SetModularFeatures) {
    const { type, payload } = action;

    switch (type) {
        case SET_MODULAR_FEATURES:
            return {
                ...payload,
            };

        default:
            return state;
    }
}